import { Modal } from "react-bootstrap";

const ModuleModal = ({ handleClose, show, item }) => {
  return (
    <>
      <div className="module_modal">
        <div>
          <Modal
            show={show}
            onHide={handleClose}
            centered
            className="module-btn-pop-up"
          >
            <Modal.Header
              className="border-bottom-0 bg-black p-0"
              closeButton
            ></Modal.Header>

            <Modal.Body className="px-2 py-0 border-bottom-0 bg-black ">
              <div className="row align-items-center  ">
                <div className="col-2 col-sm-3 col-lg-4 position-relative d-flex align-items-center justify-content-center d-none d-sm-block ">
                  <img
                    className="w-100 py-3"
                    src={item.moduleImg}
                    alt="moduleImg1"
                  />
                </div>

                <div className="col-12 col-sm-9 col-lg-8 pe-sm-0 module-overflow-hidden border_left">
                  <div className="d-flex align-items-center module-content-modal ps-2 ps-sm-0 py-3 py-sm-0 mt-4 ">
                    <div>
                      <img
                        className="w-100 py-3 d-sm-none"
                        src={item.moduleImg}
                        alt="moduleImg1"
                      />
                      <div className="">
                        <h6 className="font-md fw-bold color-light-gray pt-3 pt-sm-0 mb-2 module-one-heading">
                          Module
                        </h6>
                        <h4 className="font-lg fw-bold color-white">
                          {item.modulesubheading}
                        </h4>
                        <div className=" custom-lineheight mb-0 pe-4 pe-lg-5 ">
                          <h4 className="module_sub_heading mb-1 mt-4">
                            1. {item.moduleparaMain}
                          </h4>
                          <p className="para ps-3 ms-1">
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit.
                          </p>
                        </div>
                        <div className=" custom-lineheight   mb-0 pe-4 pe-lg-5 ">
                          <h4 className="module_sub_heading mb-1 mt-2">
                            2. {item.moduleparaMain2}
                          </h4>
                          <p className="para ps-3 ms-1">
                            Itaque tenetur consectetur sequi nihil veniam.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default ModuleModal;
