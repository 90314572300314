import React from "react";
import { Modal } from "react-bootstrap";

const ModalContentSlider = ({ handleClose, show, obj }) => {
  return (
    <>
      <Modal className="slider_modal" centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <a
              href={obj.link}
              target="_blank"
              className="d-flex align-items-center text-decoration-none"
            >
              {obj.imgUrl ? (
                <img src={obj.imgUrl} alt="Esther-Howard-img" />
              ) : null}
              <div className="ms-2 ps-1">
                <p className="font-lg fw-normal color-white mb-0 ">
                  {obj.name}
                </p>
                {obj.nameDesc ? (
                  <p className="para font-xs mb-0">{obj.nameDesc}</p>
                ) : null}
              </div>
            </a>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="para pb-3 custom-lineheight ">{obj.desc}</p>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalContentSlider;
