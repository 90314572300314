import React from "react";
import { FooterRoundedText } from "./common/Icons";
import Logo from "../assets/img/png/logo.png";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <>
      <section className="footer position-relative  overflow-hidden pt-5 mt-4 mt-sm-0 pt-lg-0">
        <img
          className="position-absolute footer-arrow logo"
          src={Logo}
          alt="svg "
        />
        <div className="container container_modified pt-lg-3">
          <div className="row">
            <div
              className="col-12 text-center text-md-start"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <h2 className="font-3xl color-lightgrey fw-medium mb-2">
                No Guarantees
              </h2>
              <p
                className="font-sm color-light-gray mb-0 pe-xl-4"
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                You agree that KodeDen has not made any guarantees about the
                results of taking any action, whether recommended on this
                website or not. KodeDen provides educational and informational
                resources that are intended to help users of this Website
                succeed in business and otherwise. You nevertheless recognize
                that your ultimate success or failure will be the result of your
                own efforts, your particular situation, and innumerable other
                circumstances beyond the control and/or knowledge of KodeDen.
              </p>
              <p
                className="font-sm fw-medium color-light-gray mb-3 mb-lg-5 pb-2"
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                All testimonials are real. The results obtained by others
                applying the principles set out in this website do not guarantee
                that you or any other person or entity will be able to obtain
                similar results. By visiting this website or interacting with
                any portion of this website, you agree that you’re fully
                responsible for the outcomes that may result.
              </p>
              <h2
                className="font-5xl fw-bold mb-0 pt-4"
                data-aos="fade-left"
                data-aos-duration="1000"
              >
                IT’S EASY
              </h2>
              <div
                className="d-flex justify-content-center justify-content-md-start align-items-center footer-margin-text overflow-hidden"
                data-aos="fade-left"
                data-aos-duration="1000"
              >
                <div className="d-none d-sm-block">
                  <h2 className="font-5xl color-white fw-bold mb-0">
                    TO GET STARTED
                  </h2>
                </div>
                <div className="d-sm-none ms-4 ms-sm-0">
                  <h2 className="font-5xl color-white fw-bold pt-5">TO</h2>
                  <h2 className="font-5xl color-white fw-bold">GET STARTED</h2>
                </div>

                <FooterRoundedText />
              </div>
            </div>
            <p
              onClick={() => navigate("/terms-and-conditions")}
              className="text-white text-center mt-2 font-sm cursor-pointer"
            >
              Terms And Conditions
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
