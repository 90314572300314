import React from "react";
import OurProgramImg from "../../assets/img/png/our-program-img.png";

const OurProgram = () => {
  return (
    <>
      <section className="py-md-5 mt-5 mt-md-0 overflow-hidden">
        <div className="container container_modified">
          <div className="row justify-content-between py-xl-5 ">
            <div
              className="col-11 col-sm-8 col-lg-5 m-auto m-lg-0"
              data-aos="fade-right"
              data-aos-delay="300"
              data-aos-duration="800"
            >
              <img
                className="w-100"
                src={OurProgramImg}
                alt="our-program-img"
              />
            </div>
            <div
              className="col-lg-6 pe-xl-5 d-flex justify-content-center flex-column pt-5 pt-lg-0"
              data-aos="zoom-in"
              data-aos-delay="300"
              data-aos-duration="800"
            >
              <h2 className="sub-heading">
                Are You A Good Fit For Our Program?
              </h2>
              <p className="para">About Who The Program is For</p>
              <p className="color-orange fw-semibold mb-0">
                Our 6-Month Software Engeering Course is for individuals that...
              </p>
              <p className="font-sm fw-normal color-gray pe-xl-5 pt-2 mt-1">
                Want to take action now but don't have years to waste in
                university Are extremely motivated and want to succeed Have
                dreamed of becoming a software engineer but don't have hundreds
                of thousands of dollars lying around
              </p>
              <p className="color-orange fw-semibold mb-0">It isn't for...</p>
              <p className="font-sm fw-normal color-gray pe-xl-5 me-xl-5 pt-2 mt-1">
                People that aren't self-starters People that don't have a sense
                of urgency to become a software engineeer People that aren't
                serious about fulfilling their dream
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurProgram;
