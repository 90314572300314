import React from "react";

const FriendlyCardItem = ({ item, id }) => {
  return (
    <>
      <div
        className={`col-10 col-sm-4 ${
          id === 1 ? "gradient-stylish-border" : ""
        }`}
        data-aos="zoom-in"
        data-aos-duration="1000"
      >
        <img
          className="mb-2"
          src={item.imgUrl}
          alt="friendly-card-img"
          id="course-img"
        />
        <h4 className="font-xl fw-semibold color-lightgrey">{item.text}</h4>
      </div>
    </>
  );
};

export default FriendlyCardItem;
