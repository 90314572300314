import React, { useState } from "react";
import instructionsHeroImg from "../../assets/img/png/instructor1.png";
import instructionsHeroImg2 from "../../assets/img/png/instructor2.png";
import { FooterRoundedText } from "../common/Icons";

const WhoAreWe = () => {
  return (
    <section className="position-relative py-5 mb-5 mt-sm-4 mt-lg-0 overflow-hidden">
      <div className="container container_modified">
        <h2 className="text-center font-3xl">
          Who Are <span className="color-orange">We</span>?
        </h2>
        <div className="row justify-content-between">
          <div
            className="col-12 col-sm-10 col-xl-5 col-lg-6 position-relative we_are_images d-flex align-items-start py-sm-5 py-4"
            data-aos="fade-right"
            data-aos-delay="300"
            data-aos-duration="800"
          >
            <img
              className="w-100 rapid_img1"
              src={instructionsHeroImg2}
              alt="instructionsHeroImg"
            />

            <div className="instructors-round-ring">
              <FooterRoundedText />
            </div>
          </div>

          <div
            data-aos="fade-left"
            data-aos-delay="300"
            data-aos-duration="800"
            className="col-12 col-lg-6 pe-xl-5 color-lightgrey mt-4 mt-lg-0 d-flex flex-column justify-content-center text-center text-lg-start"
          >
            <div className="ms-xxl-4 instructors-content">
              <p className="font-xs fw-normal mb-1">MEET YOUR INSTRUCTORS</p>
              <h2 className="font-2xl fw-medium mb-3">
                Hi, My Name Is Kody Doherty 👋
              </h2>
              <p className="color-light-gray">
                And my team knows firsthand that building a career as a software
                engineer without a computer science degree is possible. We
                started where you are *right now*—without engineering degrees, a
                professional portfolio, or years of experience. Then we climbed
                the ranks to hold senior positions at tech titans like Amazon,
                Apple, and the US government. Throughout our careers, we’ve
                managed entire teams of engineers, directed $30 million program
                budgets, and built 2 successful tech startups (one that we
                sold). Learning to code did more than add a few impressive lines
                to our resumes. It literally changed our lives…
              </p>
              <p className="color-orange">I want to live my dream now!</p>
              <div className="mt-4 pt-lg-3 pt-2">
                <a
                  href="https://app.squarespacescheduling.com/schedule.php?owner=27771236"
                  className="modal-btn common-btn text-decoration-none text-uppercase font-sm"
                >
                  Book a call with my team
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhoAreWe;
