import React, { useState } from "react";
import { LinkedIn } from "./common/Icons";
import ModalContentSlider from "./common/ModalContentSlider";

const TestimonialCard = ({ obj }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div className="px-2 mx-2 mx-sm-0">
        <div className="testiminals-card p-3 mb-1 d-flex flex-column justify-content-between">
          <div>
            <h4 className="fw-normal color-white mb-1">{obj.heading}</h4>
            <p className="para  custom-lineheight block-ellipsis">{obj.desc}</p>
            <div className="">
              <button onClick={handleShow} className="my-2 read_more">
                Read More...
              </button>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-end">
            <div target="_blank" className="d-flex text-decoration-none">
              {obj.imgUrl ? (
                <img src={obj.imgUrl} alt="Esther-Howard-img" />
              ) : null}
              <div className="ms-2 ps-1">
                <p className="font-lg fw-normal color-white mb-0 ">
                  {obj.name}
                </p>
                {obj.nameDesc ? (
                  <p className="para font-xs mb-0">{obj.nameDesc}</p>
                ) : null}
              </div>
            </div>
            <div>
              {obj.link === null ? (
                ""
              ) : (
                <a className="icon pb-1" href={obj.link}>
                  <LinkedIn />
                </a>
              )}
            </div>
          </div>
        </div>
      </div>

      <ModalContentSlider handleClose={handleClose} show={show} obj={obj} />
    </>
  );
};

export default TestimonialCard;
