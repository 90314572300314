import About from "../components/About";
import BackToTop from "../components/common/BackToTop";
import FixedIcon from "../components/common/FixedIcon";
import Header from "../components/common/Header";
import CurrentJobModal from "../components/CurrentJobModal";
import Faq from "../components/Faq";
import Footer from "../components/Footer";
import Guarantee from "../components/Guarantee";
import Hero from "../components/Hero";
import HowItWorks from "../components/HowItWorks";
import Instructors from "../components/Instructors";
import Modules from "../components/Modules";
import Program from "../components/Program";
import TestiminialsSection from "../components/TestiminialsSection";

const Home = () => {
  return (
    <>
      <FixedIcon />
      <section className="herosection-bg overflow-hidden">
        <Header />
        <Hero />
      </section>
      <About />
      <Program />
      <Modules />
      <HowItWorks />
      <TestiminialsSection number="02" />
      <Instructors />
      <section className="faq-bg">
        <Guarantee />
        <Faq />
      </section>
      <CurrentJobModal />
      <Footer />
      <BackToTop />
    </>
  );
};

export default Home;
