import React from "react";
import ArrowIconRightImg from "../../assets/img/svg/arrow-icon-right.png";

const CourseCardsData = ({ item, id }) => {
  return (
    <>
      <div className="col-12 col-md-10 col-lg-4 mt-4 mt-lg-0 cursor-pointer">
        <div className="flip-container cursor-pointer">
          <div className="flipper">
            {/* <!-- FRONT CONTENT BEFORE ON HOVER --> */}
            <div
              className="front course-card px-4 px-sm-4 px-md-3 pe-lg-0 ps-xl-3 pt-2 pb-3"
              data-aos="fade-up"
              data-aos-delay={`${(id + 1) * 200}`}
              data-aos-duration="1000"
            >
              <div className="d-flex align-items-end justify-content-between mb-1 course-card-content">
                <h2 className="font-2xl fw-semibold color-gray mb-0">
                  {item.CourseNo}
                </h2>
                <img
                  className={`master-coding-img ${
                    id === 0 && "master-coding-img2"
                  }`}
                  src={item.courseCardImg}
                  alt="courseCardImg"
                  id="course-img"
                />
              </div>
              <p className="font-md fw-bold color-lightgrey mb-0 text-start">
                {item.CourseHeading}
              </p>
              <p className="font-md fw-bold color-lightgrey mb-0 flex-wrap d-flex align-items-center">
                {item.CourseSubHeading}
                <img
                  className="ms-1"
                  src={ArrowIconRightImg}
                  alt="ArrowIconRightImg"
                />
              </p>
            </div>
            {/* <!-- back content --> */}
            <div className="back d-flex align-items-center justify-content-center ">
              <div className="course-card d-flex align-items-center justify-content-center p-3">
                <p className="para text-white mb-0">{item.backContent}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseCardsData;
