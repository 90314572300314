import React from "react";
import leftAnimatedArrowImg from "../assets/img/svg/animated-left-arrow-icon.svg";
import motionsvg from "../assets/img/svg/motion-svg-arrow-icon.svg";

const CurrentJobModal = () => {
  return (
    <>
      <section className="position-relative  mb-3  mt-3 mt-md-5 pt-lg-5">
        {/* ANIMATED IMAGE LEFT-UP SIDE ARROW */}
        <img
          className="position-absolute modal-top-arrow"
          src={leftAnimatedArrowImg}
          alt="leftAnimatedArrowImg"
        />

        <div className="container container_modified mt-xl-5">
          <div className="row justify-content-center">
            <div className="col-12 col-xl-10 text-center">
              <h2
                className="font-3xl color-lightgrey mb-0"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                What's Your Current Job
              </h2>
              <h2
                className="font-3xl color-lightgrey mb-3"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                Costing You?
              </h2>
              <p
                className="font-sm color-light-gray fw-normal mb-0"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                If you sideline your dreams of working in tech for another year,
                you might as well leave $10k - 20k on a crowded park bench. Why?
                Because that’s how much the average person increases their
                salary after transitioning to tech.
              </p>
              <p
                className="font-sm color-light-gray fw-normal mb-4 mb-lg-5"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                You’re just 6 months away. Let's do this.
              </p>
              <div
                className="mb-lg-5 pb-md-5"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <a
                  href="https://app.squarespacescheduling.com/schedule.php?owner=27771236"
                  className="modal-btn common-btn text-decoration-none"
                >
                  Schedule time with me
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* ANIMATED IMAGE RIGHT-UP SIDE ARROW */}
        <img
          className="position-absolute modal-bottom-arrow"
          src={motionsvg}
          alt="svg "
        />
      </section>
    </>
  );
};

export default CurrentJobModal;
