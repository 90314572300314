import React, { useState } from "react";
import instructionsHeroImg from "../../assets/img/png/instructor1.png";
// import instructionsHeroImg2 from "../assets/img/png/instructor2.png";
// import { instructionsParaData } from "./common/Helper";
// import { FooterRoundedText } from "./common/Icons";
// import InstructionsItem from "./common/InstructionsItem";
import CommonPointHeading from "../common/CommonPointHeading";
import InstructionsItem from "../common/InstructionsItem";
import { FooterRoundedText } from "../common/Icons";
import { instructionsParaData } from "../common/Helper";
import KlaviyoData from "../../views/KlaviyoData";

const CourseInstructors = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    window.location.reload(true);
  };
  const handleShow = () => setShow(true);
  return (
    <>
      <section className="custom-bottom-border custom-top-border position-relative instructors-section overfl ow-hidden">
        <div className="container container_modified ">
          <div className="row">
            <div className="col-12 description-section ">
              <div className="row sidebar_text_parent">
                <CommonPointHeading pointNo="03" pointText="Instructor" />
                <div className="col-12 col-md-10 pt-5 pt-sm-0">
                  <hr className="my-0" />
                  <h5 className="d-flex flex-column mb-0 about-heading-text py-2 px-3 d-lg-none">
                    <span className="mb-0 para font-lg sidebar_text">
                      Instructor
                    </span>
                  </h5>

                  <div className="ps-2 ps-md-4 ps-xl-5 py-4 py-sm-5 description-content border_increased custom-bottom-border2">
                    <div className="row my-3">
                      <div
                        className="col-12 col-sm-8 col-lg-7 col-xl-5 position-relative d-flex flex-column"
                        data-aos="fade-up"
                        data-aos-duration="1000"
                      >
                        <img
                          className="w-100  instructors_image01"
                          src={instructionsHeroImg}
                          alt="instructionsHeroImg"
                        />

                        <div className="instructors-round-ring mr-1  course-instructors-round-ring01">
                          <FooterRoundedText />
                        </div>
                      </div>

                      <div
                        className="col-12 col-lg-7 mt-4 mt-lg-0 d-flex flex-column justify-content-center"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <div className="instructors-content ms-xl-3">
                          <p className="font-xs fw-normal color-lightgrey mb-1">
                            MEET YOUR INSTRUCTOR
                          </p>
                          <h2 className="font-2xl fw-medium color-lightgrey mb-3">
                            Hi, I’m Kody Doherty 👋
                          </h2>
                          <p className="font-sm fw-normal color-light-gray mb-0 course-instructors-para">
                            I know firsthand that building a career as a
                            front-end developer without a computer science
                            degree is possible.
                          </p>
                          <p className="font-sm fw-normal color-light-gray mb-0 course-instructors-para">
                            I started where you are *right now*—without an
                            engineering degree, a professional portfolio, or
                            years of experience.
                          </p>
                          <p className="font-sm fw-normal color-light-gray mb-0 course-instructors-para">
                            Then I climbed the ranks to hold senior positions at
                            tech titans like Amazon, Apple, and the US
                            government.
                          </p>
                          <p className="font-sm fw-normal color-light-gray mb-0 course-instructors-para">
                            Throughout my career, I’ve managed entire teams of
                            engineers, directed $30 million program budgets, and
                            built 2 successful tech startups (one that we sold).
                          </p>
                          <p className="font-sm fw-bold color-white mb-0 course-instructors-para">
                            All because I learned the same skills I teach today.
                          </p>
                          <p className="font-sm fw-normal color-light-gray mb-0 course-instructors-para pe-4">
                            Learning to code did more than add a few impressive
                            lines to my resume. It literally changed my life.
                            And now my mission is to help you do the same.
                          </p>
                          <div
                            className="d-flex flex-column flex-sm-row align-items-sm-center mt-3 pt-sm-3 pt-lg-0 pt-xl-3 instructors_btns"
                            data-aos="fade-up"
                            data-aos-delay="300"
                            data-aos-duration="800"
                          >
                            <button
                              onClick={handleShow}
                              className="text-nowrap d-inline-block common-btn hero-btn position-relative font-xs course_schedule_btn fw-bold"
                            >
                              <span className="d-flex align-items-center justify-content-center justify-content-md-start">
                                Schedule 15-min Discovery Call
                              </span>
                            </button>

                            <button className="text-nowrap enroll_btn font-xs fw-bold text-white ms-sm-3 d-flex align-items-center justify-content-center mt-2 mt-sm-0">
                              <a
                                href="https://kodeden.thrivecart.com/kodedens-1-month-htmlcss-workshop/"
                                target="_blank"
                                className="text-decoration-none text-white hover-text-orange"
                              >
                                Enroll Now for $500
                              </a>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <KlaviyoData handleClose={handleClose} show={show} />
    </>
  );
};

export default CourseInstructors;
