import React from "react";
import CommonPointHeading from "../common/CommonPointHeading";
import CommonHeading from "../common/CommonHeading";
import motionsvg from "../../assets/img/svg/motion-svg-arrow-icon.svg";
import IncludedCardsData from "./IncludedCardsData";
import { courseIncludedCardsData } from "./Helper";

const WhatsIncluded = () => {
  return (
    <>
      {/* 02 */}
      <section
        className="custom-bottom-border position-relative whats_included"
        id="whats-included"
      >
        <div className="container container_modified">
          <div className="row">
            <div className="col-12 description-section">
              <div className="row sidebar_text_parent">
                <CommonPointHeading pointNo="02" pointText="What’s included?" />
                <div className="col-12 col-lg-10 pt-5 pt-md-0 ">
                  <h5 className="d-flex flex-column mb-0 about-heading-text py-2 px-3 d-lg-none">
                    <span className="mb-0 para font-lg sidebar_text">
                      What’s included?
                    </span>
                  </h5>

                  <div className="ps-2 ps-md-4 ps-xl-5 pt-4 pb-5 py-sm-5 description-content custom-bottom-border2 custom_line">
                    <div
                      className="mt-3 mb-2"
                      data-aos="fade-down"
                      data-aos-duration="1000"
                    >
                      <CommonHeading commonHeading="What’s included?" />
                    </div>
                    <p
                      className="para pb-sm-4"
                      data-aos="zoom-in"
                      data-aos-delay="200"
                      data-aos-duration="1000"
                    >
                      No fluff. No spiraling. No forgetting everything you’ve
                      learned.
                    </p>
                    <div className="row">
                      {courseIncludedCardsData &&
                        courseIncludedCardsData.map((item, index) => (
                          <IncludedCardsData
                            item={item}
                            key={index}
                            id={index}
                          />
                        ))}
                    </div>
                    <div className="py-md-3"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ANIMATED IMAGE RIGHT SIDE ARROW */}
        <img
          className="position-absolute custom-position-side-img2 d-none d-lg-block"
          src={motionsvg}
          alt="svg "
        />
      </section>
    </>
  );
};

export default WhatsIncluded;
