import React from "react";
import ReactPlayer from "react-player";
import leftAnimatedArrowImg from "../../assets/img/svg/animated-left-arrow-icon.svg";

const Hero = () => {
  return (
    <>
      <div className="overflow-hidden position-relative">
        <div className="container container_modified py-5 d-flex justify-content-center mb-xl-5 align-items-center hero-content">
          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <div data-aos="fade-right" data-aos-delay="300">
                <h2 className="text-uppercase main-heading mb-3 pb-1 font-2xl-2">
                  Start the Mini-Course:
                  <span className="linear-gradient-orange ms-2">
                    Rapidly Learning New
                    <br className="d-none d-xl-block" />
                    <span className="ms-2">Technical Skills</span>
                  </span>
                </h2>
                <h4 className="font-xl fw-semibold color-white text-uppercase mb-4 pb-1">
                  Learn All The Skills You Need To Become a
                  <br className="d-none d-sm-block" />
                  Software Engineer!
                </h4>
              </div>
              <div data-aos="fade-left" data-aos-delay="300">
                <div className="br-24 mb-3 pb-1 rapid_video overflow-hidden">
                  <ReactPlayer
                    className="br-24"
                    url="https://youtu.be/VC9e8XjCZs8"
                    playing={false}
                    volume={1}
                    width="100%"
                    muted={false}
                    controls={true}
                    height="500px"
                  />
                </div>
              </div>
              <div data-aos="fade-right" data-aos-delay="300">
                {/* ANIMATED IMAGE RIGHT-UP SIDE ARROW */}
                <img
                  className="position-absolute rapid-typing-hero d-none d-md-block"
                  src={leftAnimatedArrowImg}
                  alt="svg"
                />
                <h4 className="font-2xl pt-4 fw-semibold color-white text-uppercase mb-2">
                  Become a
                  <span className="linear-gradient-orange mx-2">Software</span>
                  Engineer Now!
                </h4>
                <p className="para mb-3 pb-3 mt-1">
                  No Math or Science Background Needed
                </p>
                <a
                  href="https://app.squarespacescheduling.com/schedule.php?owner=27771236"
                  className="modal-btn common-btn text-decoration-none text-uppercase font-sm"
                >
                  Book a call with my team
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
