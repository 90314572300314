import React from "react";
import FixedIcon from "../components/common/FixedIcon";
import Header from "../components/common/Header";
import Hero from "../components/courses/Hero";
import FriendlyCards from "../components/courses/FriendlyCards";
import FastTrack from "../components/courses/FastTrack";
import WhatsIncluded from "../components/courses/WhatsIncluded";
import CourseInstructors from "../components/courses/CourseInstructors";
import WhatYouLearn from "../components/courses/WhatYouLearn";
import TestimonialStudent from "../components/courses/TestimonialStudents";

import BackToTop from "../components/common/BackToTop";
import EnrollNow from "../components/courses/EnrollNow";
import Checkout from "../components/courses/Checkout";
import Footer from "../components/Footer";
import CourseFooter from "../components/courses/CourseFooter";
// import Instructors from "../components/Instructors";
// import TestiminialsSection from "../components/TestiminialsSection";

const CoursesPage = () => {
  return (
    <>
      <div className="bg-dange">
        <FixedIcon />
        <section className="herosection-bg overflow-hidden">
          <Header />
          <Hero />
        </section>
        <FriendlyCards />
        <FastTrack />
        <WhatsIncluded />
        <CourseInstructors />
        <WhatYouLearn />
        <TestimonialStudent />
        <EnrollNow />
        <Checkout />
        <CourseFooter />
        <BackToTop />
      </div>
    </>
  );
};

export default CoursesPage;
