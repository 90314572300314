import React from "react";
import GuaranteeTextFirst from "../assets/img/png/guarantee-text-1.png";
import GuaranteeTextSecond from "../assets/img/png/guarantee-text-2.png";
import GuaranteeWolfImg from "../assets/img/png/logo-wolf-image.png";
import motionsvg from "../assets/img/svg/motion-svg-arrow-icon.svg";

const Guarantee = () => {
  return (
    <>
      <div className="guarantee-section position-relative my-xl-5 py-lg-5">
        <div className="container_modified container mt-5 mb-md-5 ">
          <div className="row">
            <div
              className="col-12 col-md-7 ps-md-5 border-left order-2 order-md-1 pt-3 pb-3 pb-lg-5 mt-4 mt-sm-5  mt-md-0 text-center text-md-start"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <h3 className="font-xl color-lightgrey fw-normal mb-2 mb-md-4">
                We Offer a 15-Day
              </h3>
              <img
                className="guarantee-text"
                src={GuaranteeTextFirst}
                alt="GuaranteeTextFirst"
              />
              <br />
              <img
                className="guarantee-text mt-2 mt-sm-0"
                src={GuaranteeTextSecond}
                alt="GuaranteeTextSecond"
              />
              <p className="color-light-gray font-xl mb-0 fw-normal mt-2 mt-md-3">
                We offer a job placement guarantee for Full Stack and Front End
                Web Developer programs. We guarantee that after you complete the
                Program, we will provide you with a full refund if you don't
                find a job in the industry within six months.
              </p>
              <ol className="ps-3 ms-1 pt-2">
                <li className="color-light-gray py-1">
                  Complete the entire Program with a coach signing off on all
                  your projects.
                </li>
                <li className="color-light-gray py-1">
                  Apply for three new jobs in the industry each week and provide
                  proof to your coach.
                </li>
                <li className="color-light-gray py-1">
                  Complete one Capstone Project at the end of the course where
                  you build a web application for an organization, charity, or
                  business that you can donate for them to use. This step is
                  critical to help build out your real-world experience.
                </li>
                <li className="color-light-gray py-1">
                  Create one post each week on LinkedIn related to Software
                  development.
                </li>
              </ol>
            </div>
            <div
              className="col-12 col-md-5 ps-lg-5 ps-xl-0 pe-xl-5 order-1 order-md-2 text-center text-md-start"
              data-aos="zoom-in"
              data-aos-duration="1000"
            >
              <img
                className="guarantee-wolf-img w-100 py-xl-4"
                src={GuaranteeWolfImg}
                alt="GuaranteeWolfImg"
              />
            </div>
          </div>
        </div>

        {/* ANIMATED IMAGE RIGHT-UP SIDE ARROW */}
        <img
          className="guarantee-arrow position-absolute"
          src={motionsvg}
          alt="svg "
        />
      </div>
    </>
  );
};

export default Guarantee;
