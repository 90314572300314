import React from "react";
import CommonHeading from "./common/CommonHeading";
import CommonPointHeading from "./common/CommonPointHeading";
import motionsvg from "../assets/img/svg/motion-svg-arrow-icon.svg";
import leftDownAnimatedArrowImg from "../assets/img/svg/left-down-arrow-animated.svg";
import AboutItemData from "./common/AboutItemData";
import CourseCardsData from "./common/CourseCardsData";
import { aboutItemData, courseData } from "./common/Helper";

const About = () => {
  return (
    <>
      {/* 01 */}
      <section
        className="custom-bottom-border position-relative custom-bottom-border3"
        id="about"
      >
        {/* ANIMATED IMAGE LEFT SIDE ARROW */}
        <img
          className="position-absolute custom-position-side-img d-none d-lg-block"
          src={leftDownAnimatedArrowImg}
          alt="leftDownAnimatedArrowImg"
        />

        <div className="container container_modified">
          <div className="row">
            <div className="col-12 description-section">
              <div className="row">
                <CommonPointHeading pointText="About" />
                <div className="col-12 col-md-10 pt-5 pt-md-0 ">
                  <h5 className="d-flex flex-column mb-0 about-heading-text py-2 px-3 d-lg-none">
                    <span className="mb-0 para font-lg sidebar_text">
                      About
                    </span>
                  </h5>
                  <div className="ps-md-4 ps-xl-5 py-4 py-sm-5 description-content custom-bottom-border2 my-custom-height">
                    <h2
                      className="sub-heading text-capitalize mt-md-3"
                      data-aos="fade-down"
                      data-aos-duration="1000"
                    >
                      Software Engineers are among the highest-paid employees
                      America. This could be you, in 4 to 6 months
                    </h2>
                    <ul className="list-unstyled">
                      {aboutItemData.map((obj, index) => (
                        <AboutItemData obj={obj} key={index} />
                      ))}
                    </ul>
                  </div>

                  <div className="ps-md-4 ps-xl-5 pt-4 pb-5 py-sm-5 description-content custom-bottom-border2 custom_line">
                    <div
                      className="mt-3 mb-2"
                      data-aos="fade-down"
                      data-aos-duration="1000"
                    >
                      <CommonHeading commonHeading=" Join KodeDen's 4 or 6-Month Software Engineering Courses" />
                    </div>
                    <p
                      className="para pb-4"
                      data-aos="zoom-in"
                      data-aos-delay="200"
                      data-aos-duration="1000"
                    >
                      Learn everything you need to know to break into tech from
                      senior engineers with experience at Apple, Amazon, and the
                      DOD.
                    </p>
                    <p
                      className="para pb-3 color-white"
                      data-aos="zoom-in"
                      data-aos-delay="300"
                      data-aos-duration="1000"
                    >
                      Through weekly live lectures and at-home exercises,
                      you’ll:
                    </p>

                    <div className="row">
                      {courseData.map((item, index) => (
                        <CourseCardsData item={item} key={index} id={index} />
                      ))}
                    </div>
                    <div className="py-md-3"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ANIMATED IMAGE RIGHT SIDE ARROW */}
        <img
          className="position-absolute custom-position-side-img2 d-none d-md-block"
          src={motionsvg}
          alt="svg "
        />
      </section>
    </>
  );
};

export default About;
