import { useState, useEffect } from "react";
import { BackToTopIcon } from "./Icons";

export default function BackToTop() {
  const [isVisible, setIsVisible] = useState(false);
  const [pageYOffset, setPageYOffset] = useState(0);

  // SCROLL TO TOP FUNCTION WHEN CLICK ON THIS PAGE SCROLL TOP
  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  // TO FIND SCROLL Y POSITION
  useEffect(() => {
    window.addEventListener("scroll", function () {
      setPageYOffset(window.pageYOffset);
    });
  }, []);

  // THIS USEFFECT GIVE US POSITION OF SCROLL AT Y-AXIS IN EVERY PIXELS OF PAGE WHEN WE SCROLL AND
  // IF PAGE SCROLL VALUE GRATER THAN 300 THEN SHOW BACK TO TOP BUTTON
  useEffect(() => {
    if (pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [pageYOffset]);

  return (
    <div className="scroll-to-top">
      {isVisible && (
        <div onClick={() => scrollToTop()}>
          <BackToTopIcon />
        </div>
      )}
    </div>
  );
}
