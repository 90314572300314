import React from "react";
import { ListCheckIcon } from "../common/Icons";

const WhatYouLearnData = ({ item, id }) => {
  return (
    <>
      <div className="row overflow-hidden">
        <div
          className="col-4 col-sm-2 learn-data-img"
          data-aos="zoom-in"
          data-aos-delay="200"
          data-aos-duration="1000"
        >
          <img
            className="w-100 mb-2 mb-sm-0"
            src={item.imgUrl}
            alt="LearnDataImg"
          />
        </div>
        <div
          className="col-12 col-sm-9 col-md-7"
          data-aos="fade-left"
          data-aos-delay="200"
          data-aos-duration="1000"
        >
          <h4 className="font-xl fw-normal color-lightgrey mb-2">
            {item.heading}
          </h4>
          <div className="d-flex justify-content-between learn-data-list-content01">
            <ul className="list-unstyled mb-0">
              {item.learnListItemData &&
                item.learnListItemData.map((item, index) => (
                  <li
                    className="d-flex align-items-center para color-gray mb-1 mb-sm-2"
                    key={index}
                  >
                    <ListCheckIcon />
                    <span className="ms-2">{item.listItem}</span>
                  </li>
                ))}
            </ul>
            <ul className="list-unstyled mb-0">
              {item.learnListItemData2 &&
                item.learnListItemData2.map((item, index) => (
                  <li
                    className="d-flex align-items-center para color-gray mb-1 mb-sm-2"
                    key={index}
                  >
                    <ListCheckIcon />
                    <span className="ms-2">{item.listItem}</span>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>

      {id === 3 ? (
        ""
      ) : (
        <div className="py-2">
          <div className="my-2 my-sm-4 custom-bottom-border2"></div>
        </div>
      )}
    </>
  );
};

export default WhatYouLearnData;
