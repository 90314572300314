import React, { useState } from "react";
import KlaviyoData from "../../views/KlaviyoData";

const Hero = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    window.location.reload(true);
  };
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="overflow-hidden">
        <div className="container container_modified py-5 d-flex justify-content-center align-items-center hero-content course_content">
          <div className="row justify-content-center align-items-center">
            <div className="col-12 text-center text-xl-start ms-xl-5">
              <div className="row align-items-center justify-content-center">
                <div className="col-12 col-lg-8 col-xxl-7">
                  <h2
                    className="text-uppercase main-heading mb-0"
                    data-aos="fade-up"
                    data-aos-delay="300"
                    data-aos-duration="800"
                  >
                    HTML/CSS and
                  </h2>
                </div>
                <div className="col-12 col-lg-4 mt-2 mt-md-4 mt-lg-0">
                  <p
                    className="para text-lg-start mb-0 pe-xxl-4"
                    data-aos="fade-up"
                    data-aos-delay="300"
                    data-aos-duration="800"
                  >
                    Learn modern web design and CSS in one month. So you can
                    create beautiful, functional websites from scratch and get
                    paid for it.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-12 text-center mt-3 my-xl-4">
              <h2
                className="text-uppercase main-heading mb-0"
                data-aos="fade-up"
                data-aos-delay="300"
                data-aos-duration="800"
              >
                Modern Website
              </h2>
            </div>

            <div className="col-12 text-center text-xl-start ms-lg-5 ps-lg-3 my-2 my-lg-0">
              <div className="row align-items-center justify-content-xl-center">
                <div className="col-12 col-lg-4 order-1 order-lg-0 col-xl-3">
                  <p
                    className="para text-white text-center text-lg-start mb-0"
                    data-aos="fade-up"
                    data-aos-delay="300"
                    data-aos-duration="800"
                  >
                    Date:
                  </p>
                  <p
                    className="para text-center text-lg-start mb-3"
                    data-aos="fade-up"
                    data-aos-delay="300"
                    data-aos-duration="800"
                  >
                    05/1/2023 - 06/01/2023
                  </p>
                  <p
                    className="para text-white text-center text-lg-start mb-0"
                    data-aos="fade-up"
                    data-aos-delay="300"
                    data-aos-duration="800"
                  >
                    Duration:
                  </p>

                  <div className="d-flex align-items-center justify-content-center justify-content-lg-start  text-nowrap">
                    <p
                      className="para text-center text-white text-xl-start mb-0"
                      data-aos="fade-up"
                      data-aos-delay="300"
                      data-aos-duration="800"
                    >
                      4 Weeks
                    </p>
                    <p
                      className="para text-center text-xl-start mb-0 mx-3"
                      data-aos="fade-up"
                      data-aos-delay="300"
                      data-aos-duration="800"
                    >
                      Day: 30
                    </p>
                    <p
                      className="para text-center text-xl-start mb-0"
                      data-aos="fade-up"
                      data-aos-delay="300"
                      data-aos-duration="800"
                    >
                      Time: 730.08 hours
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-7 order-0 order-lg-1 col-xl-8 col-xxl-7 mb-3 mb-sm-0">
                  <h1
                    className="text-uppercase main-heading mb-0 text-nowrap"
                    data-aos="fade-up"
                    data-aos-delay="300"
                    data-aos-duration="800"
                  >
                    Design Course
                  </h1>
                </div>
              </div>
            </div>
            {/* ========== BUTTONS ========== */}
            <div
              className="col-12 text-center mt-4"
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-duration="800"
            >
              <div className="d-flex flex-column flex-md-row align-items-center justify-content-center mt-4 mt-md-5">
                <button
                  onClick={handleShow}
                  className="d-inline-block fw-bold common-btn hero-btn position-relative font-xs course_schedule_btn"
                >
                  <span className="d-flex align-items-center justify-content-center justify-content-md-start">
                    Schedule 15-min Discovery Call
                  </span>
                </button>

                <button className="enroll_btn font-xs fw-bold text-white ms-md-3 d-flex align-items-center justify-content-center mt-2 mt-md-0">
                  <a
                    href="https://kodeden.thrivecart.com/kodedens-1-month-htmlcss-workshop/"
                    target="_blank"
                    className="text-decoration-none text-white hover-text-orange"
                  >
                    Enroll Now for $500
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <KlaviyoData handleClose={handleClose} show={show} />
    </>
  );
};

export default Hero;
