import React from "react";
import { Modal } from "react-bootstrap";

const KlaviyoData = ({ show, handleClose }) => {
  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg" centered>
        <Modal.Body>
          <div className="container pb-4 d-flex flex-column justify-content-center">
            <div className="text-end">
              <button onClick={handleClose} className="btn-close"></button>
            </div>
            <div className="d-none d-lg-block">
              <div className="klaviyo-form-UT5UUw"></div>
            </div>
            <div className="d-lg-none">
              <div className="klaviyo-form-U5ETAL"></div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default KlaviyoData;
