import React from "react";
import CourseCardsData from "../common/CourseCardsData";
import { courseData } from "../common/Helper";
import motionsvg from "../../assets/img/svg/motion-svg-arrow-icon.svg";

const CourseCards = () => {
  return (
    <>
      <div className="overflow-hidden py-sm-5 py-4 position-relative">
        <div className="container px-xxl-0 container_modified py-lg-5">
          <div className="row justify-content-center align-items-center">
            <div
              className="col-12 position-relative text-center"
              data-aos="zoom-in"
              data-aos-delay="300"
              data-aos-duration="800"
            >
              <h2 className="sub-heading">
                “From Confused to Coder”: Kodeden's Exclusive{" "}
                <br className="d-none d-xxl-block" /> 6-Month Course
                <span className="linear-gradient-orange mx-2">
                  Software Engineering
                </span>
                Course?
              </h2>
              <p className="para color-light-grey">
                Through weekly live lectures and at-home exercises, you’ll:
              </p>
              <p className="para color-gray mb-xl-5 pb-md-4 mb-0">
                Learn everything you need to know to break into tech from senior
                engineers with experience at
                <br className="d-none d-xl-block" />
                Apple, Amazon, and the DOD.
              </p>
            </div>

            <div className="row mt-3 justify-content-center justify-content-lg-start">
              {courseData.map((item, index) => (
                <CourseCardsData item={item} key={index} id={index} />
              ))}
            </div>
          </div>
        </div>
        {/* ANIMATED IMAGE RIGHT-UP SIDE ARROW */}
        <img
          className="position-absolute course-card-arrow d-none d-md-block"
          src={motionsvg}
          alt="svg "
        />
      </div>
    </>
  );
};

export default CourseCards;
