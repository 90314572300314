import React from "react";

const FastTrackData = ({ item, id }) => {
  return (
    <>
      <div>
        <div className="d-flex flex-column flex-md-row align-items-start pt-4 pt-md-1">
          <img
            src={item.courseCardImg}
            alt="monitor"
            className="monitor_max_w"
            id="course-img"
          />
          <div className="ms-md-4 ps-md-1 mt-2 mt-md-0">
            <p
              className="mb-2 font-md fw-medium pe-xl-5"
              dangerouslySetInnerHTML={{
                __html: item.redText,
              }}
            ></p>

            <p className="mb-1 color-gray fw-normal font-sm">
              {item.CourseSubHeading}
            </p>
            <p className="mb-0 color-gray fw-normal font-sm">
              {item.backContent}
            </p>
          </div>
        </div>
        {id < 2 ? <div className="my-4 custom-bottom-border2"></div> : ""}
      </div>
    </>
  );
};

export default FastTrackData;
