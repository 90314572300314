import React from "react";
import FriendlyCardItem from "./FriendlyCardItem";
import { friendlyCardsData } from "./Helper";

const FriendlyCards = () => {
  return (
    <>
      <div className="friendly-section">
        <div className="container container_modified py-5">
          <div className="row justify-content-center text-center mb-1">
            {friendlyCardsData &&
              friendlyCardsData.map((item, index) => (
                <FriendlyCardItem item={item} key={index} id={index} />
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default FriendlyCards;
