import FriendlyImg1 from "../../assets/img/png/friendly-card-img-1.png";
import FriendlyImg2 from "../../assets/img/png/friendly-card-img-2.png";
import FriendlyImg3 from "../../assets/img/png/friendly-card-img-3.png";

import IncludedCardImg1 from "../../assets/img/png/included-card-img-1.png";
import IncludedCardImg2 from "../../assets/img/png/included-card-img-2.png";
import IncludedCardImg3 from "../../assets/img/png/included-card-img-3.png";
import IncludedCardImg4 from "../../assets/img/png/included-card-img-4.png";
import IncludedCardImg5 from "../../assets/img/png/included-card-img-5.png";
import IncludedCardImg6 from "../../assets/img/png/included-card-img-6.png";

import courseCardImg1 from "../../assets/img/png/monitor.png";
import courseCardImg2 from "../../assets/img/png/tab.png";
import courseCardImg3 from "../../assets/img/png/dolor.png";

import everyImg1 from "../../assets/img/png/coching.png";
import everyImg2 from "../../assets/img/png/computer.png";
import everyImg3 from "../../assets/img/png/laptop.png";

import card1 from "../../assets/img/png/card1.png";
import card2 from "../../assets/img/png/card2.png";
import card3 from "../../assets/img/png/card3.png";
import card4 from "../../assets/img/png/card4.png";

import LearnDataImg from "../../assets/img/svg/week-1-img.svg";
import LearnDataImg2 from "../../assets/img/svg/week-2-img.svg";
import LearnDataImg3 from "../../assets/img/svg/week-3-img.svg";
import LearnDataImg4 from "../../assets/img/svg/week-4-img.svg";

export const friendlyCardsData = [
  {
    imgUrl: FriendlyImg1,
    text: "Beginner friendly",
  },
  {
    imgUrl: FriendlyImg2,
    text: " Bite-sized weekly lectures",
  },
  {
    imgUrl: FriendlyImg3,
    text: " Bi-weekly coaching calls",
  },
];
export const courseIncludedCardsData = [
  {
    imgUrl: IncludedCardImg1,
    heading: "Weekly video lessons",
    para: "Watch when and where you want",
  },
  {
    imgUrl: IncludedCardImg2,
    heading: "6-8 weekly exercises + challenges",
    para: "Watch when and where you want",
  },
  {
    imgUrl: IncludedCardImg3,
    heading: "Daily check-ins",
    para: "Stay accountable and make progress",
  },

  {
    imgUrl: IncludedCardImg4,
    heading: "2 private coaching sessions",
    para: "Your questions — answered.",
  },
  {
    imgUrl: IncludedCardImg5,
    heading: "1 professional portfolio project",
    para: "Show off your skills and land the job",
  },
  {
    imgUrl: IncludedCardImg6,
    heading: "Weekly Live Q&A Sessions",
    para: "Get hands on support",
  },
];
export const courseData2 = [
  {
    courseCardImg: courseCardImg1,
    redText: ` <p>You want a <i class="color-red">comprehensive guide</i> for building beautiful, functional websites fast and making a living from it</p>`,
    CourseSubHeading:
      "Our HTML/CSS course is an all-in-one resource for learning how to design engaging and professional UI interfaces taught by Kody Doherty—a development expert with 3 years of industry experience.",
    backContent: `On top of learning modern concepts like flexbox, grid, CSS vars, and tailwind, you’ll discover productivity hacks and rapid learning strategies that will put you at the top of your game. And keep you there.`,
  },
  {
    courseCardImg: courseCardImg2,
    redText: ` <p>You want more than a course…you want a <i class="color-red">personal coach</i> to keep you accountable, speed up your progress, and get you across the finish line</p>`,
    CourseSubHeading:
      "Only 10% of people complete self-paced online courses. And for most, the second you turn off your laptop is the second you forget everything you just “learned.”",
    backContent: `Our bi-weekly, live coaching sessions ensure you overcome any mental blocks and get answers you can count on to your questions. So you can build clear mental models of how things work and whip up unique websites without the usual frustration and wasted time.`,
  },
  {
    courseCardImg: courseCardImg3,
    redText: ` <p>You want <i class="color-red">a rock-solid foundation</i> for a more intensive boot camp or higher-paying job</p>`,
    CourseSubHeading:
      "CSS/HTML are foundational technologies most boot camps skip because they think you should know them already. That leaves many with gaps in their knowledge—and results in frantic on-the-job Googling, bug-infested website builds, and missed opportunities.",
    backContent: `In this month-long intensive, you’ll master concepts you can build on to create the ultimate skill set—Whether that’s React, Angular, Vue, or something else entirely, you’ll have the base you need to build the career and life you want.`,
  },
];
export const evrythingData = [
  {
    everyImg: everyImg1,
  },
  {
    everyHeading: "Hands-on exercises",
    everyApply:
      "Apply what you’ve learned each week with 2 hours of daily exercises and challenges that will solidify your understanding.",
  },
  {
    everyImg: everyImg2,
  },
  {
    everyHeading: "Watch-when-you want lectures",
    everyApply:
      "Bite-sized, pre-recorded video lessons break down concepts into manageable blocks. Watch at your own pace, from your own place.",
  },
  {
    everyImg: everyImg3,
  },
  {
    everyHeading: "1:1 expert coaching",
    everyApply:
      "Overcome blockages. Get tailored feedback. And cross the finish line. You’ll get the emotional and professional support you need to succeed.",
  },
];
// TESTIMINALS SECTION SLIDER CARDS DATA
export const studentSliderData = [
  {
    desc: `Odio tempor, ut imperdiet viverra est vestibulum id massa arcu. Sapien lacinia molestie euismod sapien tellus. Rhoncus lobortis porta risus in. Orci, viverra tortor, augue nisl facilisi at lacus molestie. Quam sit sem a turpis at augue eget risus rhoncus rhoncus.`,
    imgUrl: card1,
    name: "Esther Howard",
  },
  {
    desc: `Et morbi amet, sed faucibus tincidunt. Laoreet nunc nisl in accumsan non, quam dignissim. Non in pellentesque et, viverra cras quis. Tristique amet et massa turpis. Ante porta vulputate turpis in sem vitae non rhoncus, urna. Id pretium morbi velit non tincidunt.`,
    imgUrl: card2,
    name: "Giana Dorwart",
  },
  {
    desc: `Ut dui dignissim sit pellentesque dictumst egestas imperdiet. Donec sed tempus pellentesque mauris. Egestas consequat magnis gravida gravida venenatis eleifend sit. At urna ac tortor, purus tortor convallis blandit lacus. Vel ac purus orci tristique. Gravida.`,
    imgUrl: card3,
    name: "Jordyn Lipshutz",
  },
  {
    desc: `A varius adipiscing commodo urna. Volutpat in aliquam senectus in fusce. Mauris, eget mi vestibulum proin. Arcu integer sed vivamus nisi. Senectus arcu auctor augue in aliquam vitae sed. Mauris consectetur volutpat est in erat scelerisque adipiscing massa. Pretium.`,
    imgUrl: card4,
    name: "Aspen Vetrovs",
  },
  {
    desc: `Odio tempor, ut imperdiet viverra est vestibulum id massa arcu. Sapien lacinia molestie euismod sapien tellus. Rhoncus lobortis porta risus in. Orci, viverra tortor, augue nisl facilisi at lacus molestie. Quam sit sem a turpis at augue eget risus rhoncus rhoncus.`,
    imgUrl: card1,
    name: "Esther Howard",
  },
  {
    desc: `Et morbi amet, sed faucibus tincidunt. Laoreet nunc nisl in accumsan non, quam dignissim. Non in pellentesque et, viverra cras quis. Tristique amet et massa turpis. Ante porta vulputate turpis in sem vitae non rhoncus, urna. Id pretium morbi velit non tincidunt.`,
    imgUrl: card2,
    name: "Giana Dorwart",
  },
  {
    desc: `Ut dui dignissim sit pellentesque dictumst egestas imperdiet. Donec sed tempus pellentesque mauris. Egestas consequat magnis gravida gravida venenatis eleifend sit. At urna ac tortor, purus tortor convallis blandit lacus. Vel ac purus orci tristique. Gravida.`,
    imgUrl: card3,
    name: "Jordyn Lipshutz",
  },
  {
    desc: `A varius adipiscing commodo urna. Volutpat in aliquam senectus in fusce. Mauris, eget mi vestibulum proin. Arcu integer sed vivamus nisi. Senectus arcu auctor augue in aliquam vitae sed. Mauris consectetur volutpat est in erat scelerisque adipiscing massa. Pretium.`,
    imgUrl: card4,
    name: "Aspen Vetrovs",
  },
];

export const whatYouLearnData = [
  {
    imgUrl: LearnDataImg,
    heading: "HTML and CSS Basics",
    learnListItemData: [
      { listItem: "HTML & HTML5" },
      { listItem: "Basic CSS" },
      { listItem: "CSS Units of Measurement" },
      { listItem: "CSS Color System" },
    ],
    learnListItemData2: [
      { listItem: "CSS Pseudo Classes" },
      { listItem: "CSS Box Model" },
      { listItem: "Font Awesome" },
    ],
  },
  {
    imgUrl: LearnDataImg2,
    heading: "CSS Positioning and Design",
    learnListItemData: [
      { listItem: "CSS Absolute Positioning" },
      { listItem: "CSS Grid" },
      { listItem: "CSS Flexbox" },
      { listItem: "Replicating Pixel Perfect Designs" },
    ],
  },
  {
    imgUrl: LearnDataImg3,
    heading: "Animations and Responsive Design",
    learnListItemData: [
      { listItem: "Animation" },
      { listItem: "KeyFrames" },
      { listItem: "Transitions" },
      { listItem: "Transforms" },
    ],
    learnListItemData2: [
      { listItem: "Mobile Design" },
      { listItem: "Media Queries" },
    ],
  },
  {
    imgUrl: LearnDataImg4,
    heading: "Tailwinds and Project Week",
    learnListItemData: [
      { listItem: "Tailwinds" },
      { listItem: "Project week!" },
    ],
  },
];
