import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./App.css";
import "./helper.css";
import "./rapid-learning.css";
import Home from "./views/Home";
import TermsAndConditions from "./views/TermsAndConditions";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import RapidLearning from "./views/RapidLearning";
import KlaviyoData from "./views/KlaviyoData";
import ThankUPage from "./views/ThankUPage";
import CoursesPage from "./views/CoursesPage";

function App() {
  AOS.init({ once: true });

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/rapid-learning" element={<RapidLearning />} />
          <Route path="/thank-you" element={<ThankUPage />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/courses" element={<CoursesPage />} />
          <Route
            path="/https://kode-den.web.app/terms-and-conditions"
            element={<TermsAndConditions />}
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
