import React from "react";
import instructionsHeroImg from "../assets/img/png/instructor1.png";
import instructionsHeroImg2 from "../assets/img/png/instructor2.png";
import CommonPointHeading from "./common/CommonPointHeading";
import { instructionsParaData } from "./common/Helper";
import { FooterRoundedText } from "./common/Icons";
import InstructionsItem from "./common/InstructionsItem";

const Instructors = () => {
  return (
    <>
      <section className="custom-bottom-border custom-top-border position-relative ">
        <div className="container container_modified ">
          <div className="row">
            <div className="col-12 description-section ">
              <div className="row">
                <CommonPointHeading pointText="Instructors" />
                <div className="col-12 col-md-10 pt-4 pt-sm-0  ">
                  <hr className="my-0" />
                  <h5 className="d-flex flex-column mb-0 about-heading-text py-2 px-3 d-lg-none ">
                    <span className="mb-0 para font-lg sidebar_text">
                      Instructors
                    </span>
                  </h5>

                  <div className="ps-md-4 ps-xl-5 py-4 py-sm-5 description-content  border_increased custom-bottom-border2 ">
                    <div className="row ps-2 ps-lg-auto justify-content-lg-center">
                      <div
                        className="col-12 col-sm-8 col-md-7 col-lg-6 position-relative  mw_500 d-flex flex-column justify-content-center"
                        data-aos="fade-right"
                        data-aos-duration="1000"
                      >
                        <img
                          className="w-100 pe-5 pb-3 pb-lg-0 img1"
                          src={instructionsHeroImg}
                          alt="instructionsHeroImg"
                        />

                        <div className="instructors-round-ring">
                          <FooterRoundedText />
                        </div>
                      </div>

                      <div
                        className="col-12 col-lg-6 color-lightgrey mt-4 mt-lg-0 d-flex flex-column justify-content-center"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <div className="ms-xxl-4 instructors-content">
                          <p className="font-xs fw-normal mb-1">
                            MEET YOUR INSTRUCTORS
                          </p>
                          <h2 className="font-2xl fw-medium mb-3">
                            Hi, My Name Is Kody Doherty👋
                          </h2>
                          {instructionsParaData.map((item, index) => (
                            <InstructionsItem
                              item={item}
                              key={index}
                              id={index}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Instructors;
