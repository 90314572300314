import React from "react";

const ProgramItem = ({ item }) => {
  return (
    <>
      <div
        className="col-12 mb-sm-1 mb-2 mt-sm-2 cursor-pointer overflow-hidden"
        data-aos="zoom-in"
        data-aos-duration="1000"
      >
        <div className="program_card">
          <p className="custom-lineheight pe-xxl-4 mb-0">{item.programPara}</p>
        </div>
      </div>
    </>
  );
};

export default ProgramItem;
