import React from "react";

const StudentsCards = ({ obj }) => {
  return (
    <>
      <div>
        <div className="px_12 ">
          <div className="testiminals-card p_12 mb-1 d-flex flex-column justify-content-between">
            <div>
              <h4 className="fw-normal color-white mb-1">
                Volutpat et, tortor, faucib us vulp tate aliquam.
              </h4>
              <p className="para  custom-lineheight block-ellipsis mb-4 pb-2">
                {obj.desc}
              </p>
            </div>
            <div className="d-flex justify-content-between align-items-end">
              <div target="_blank" className="d-flex text-decoration-none">
                {obj.imgUrl ? (
                  <img src={obj.imgUrl} alt="Esther-Howard-img" />
                ) : null}
                <div className="ms-2 ps-1">
                  <p className="font-lg fw-normal color-white mb-0 ">
                    {obj.name}
                  </p>
                  <p className="para font-xs mb-0">@Esther Howard</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentsCards;
