import React from "react";
import Logo from "../../assets/img/png/logo.png";
import { useNavigate } from "react-router-dom";

const CourseFooter = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="custom_border_top">
        <div className="container container_modified py-sm-4 my-3">
          <div className="d-flex flex-column flex-sm-row align-items-center justify-content-between">
            <img className="logo-icon" src={Logo} alt="svg " />
            <div className="d-flex align-items-center justify-content-between mt-3 mt-sm-0 course_footer_links">
              <p
                onClick={() => navigate("/terms-and-conditions")}
                className="text-white text-center mb-0 font-sm cursor-pointer op-07"
              >
                Privacy Policy
              </p>
              <p
                onClick={() => navigate("/terms-and-conditions")}
                className="text-white text-center mb-0 font-sm cursor-pointer op-07 mx-2"
              >
                | Terms of Service |
              </p>
              <p
                onClick={() => navigate("/terms-and-conditions")}
                className="text-white text-center mb-0 font-sm cursor-pointer op-07"
              >
                Cookie Policy
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseFooter;
