import React from "react";
import CommonPointHeading from "../common/CommonPointHeading";
// import AboutItemData from "../common/AboutItemData";
import leftDownAnimatedArrowImg from "../../assets/img/svg/left-down-arrow-animated.svg";
import CommonHeading from "../common/CommonHeading";
// import { courseData } from "../common/Helper";
// import CourseCardsData from "../common/CourseCardsData";
import motionsvg from "../../assets/img/svg/motion-svg-arrow-icon.svg";
import { courseData2 } from "./Helper";
import Everything from "./Everything";
import FastTrackData from "./FastTrackData";

const FastTrack = () => {
  return (
    <>
      {/* 01 */}
      <section
        className="custom-bottom-border position-relative custom-bottom-border03"
        id="about"
      >
        {/* <span className="every_botom_border"></span> */}
        {/* ANIMATED IMAGE LEFT SIDE ARROW */}
        <img
          className="position-absolute custom-position-side-img d-none d-lg-block"
          src={leftDownAnimatedArrowImg}
          alt="leftDownAnimatedArrowImg"
        />

        <div className="container container_modified">
          <div className="row">
            <div className="col-12 description-section">
              <div className="row sidebar_text_parent">
                <CommonPointHeading
                  pointNo="01"
                  pointText="HTML/CSS
Skills"
                />
                <div className="col-12 col-lg-10 pt-5 pt-md-0 ">
                  <h5 className="d-flex flex-column mb-0 about-heading-text py-2 px-3 d-lg-none">
                    <span className="mb-0 para font-lg sidebar_text">
                      01 HTML/CSS Skills
                    </span>
                  </h5>
                  <div className="ps-md-4 ps-xl-5 py-4 py-sm-5 description-content custom-bottom-border2 my-custom-height overflow-hidden position-relative">
                    <h2
                      className="sub-heading text-capitalize mt-md-3"
                      data-aos="fade-down"
                      data-aos-duration="1000"
                    >
                      Fast-track your HTML/CSS skills with this highly
                      interactive course taught by industry experts with
                      <i> experience at Apple and Amazon.</i>
                    </h2>
                    <p className="para color-dark-gray mb-2">
                      Through bite-sized weekly video lessons, exercises, and
                      coaching calls, you’ll master the technologies behind the
                      internet’s most engaging and useful websites.
                    </p>
                    <p className="para color-dark-gray mb-2">
                      So you can build your own website, grow your side hustle,
                      or take your career to the next level.
                    </p>
                    <a
                      href="https://kodeden.thrivecart.com/kodedens-1-month-htmlcss-workshop/"
                      target="_blank"
                      className="text-decoration-none d-inline-block common-btn sign_btn hero-btn fw-bold position-relative font-xs course_schedule_btn mt-4"
                    >
                      Checkout Now
                    </a>
                  </div>

                  <div className="ps-md-4 ps-xl-5 pt-4 pb-5 py-sm-5 description-content custom_line  position-relative overflow-hidden">
                    <span className="border_bottom overflow-hidden"></span>
                    <div
                      className="mt-3 mb-2"
                      data-aos="fade-down"
                      data-aos-duration="1000"
                    >
                      <CommonHeading commonHeading=" This course is for you if…" />
                    </div>
                    <p
                      className="para pb-4"
                      data-aos="zoom-in"
                      data-aos-delay="200"
                      data-aos-duration="1000"
                    >
                      Learn everything you need to know to break into tech from
                      senior engineers with experience at Apple, Amazon, and the
                      DOD.
                    </p>

                    <div>
                      {courseData2.map((item, index) => (
                        <FastTrackData item={item} key={index} id={index} />
                      ))}
                    </div>
                    <div className="py-md-3"></div>
                  </div>

                  <Everything />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ANIMATED IMAGE RIGHT SIDE ARROW */}
        <img
          className="position-absolute custom-position-side-img02 d-none d-md-block"
          src={motionsvg}
          alt="svg "
        />
      </section>
    </>
  );
};

export default FastTrack;
