import React from "react";
import Header from "../components/common/Header";
import BackToTop from "../components/common/BackToTop";
import { useEffect } from "react";

const TermsAndConditions = () => {
  // FOR SCROLL TO TOP OF THE PAGE
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <BackToTop />

      <section className="py-4 py-sm-5">
        <div className="container">
          <div className="row">
            <div className="col">
              <h1 className="text-center font-3xl mt-5">Kodenden, LLC</h1>
              <h1 className="text-center font-3xl">Terms and Conditions and</h1>
              <h1 className="text-center font-3xl">
                Instructional Service Disclosures
              </h1>

              <h4 className="mt-4 mt-sm-5 font-md color-light-gray ">
                Please carefully read these Instructional Service Disclosures
                and Terms and Conditions (this “Agreement”) carefully as this
                Agreement is a legally binding agreement between you
                individually and any entity on whose behalf you are using the
                program (collectively “you” and/or “your”), and Kodenden, LLC, a
                Nevada Limited Liability Company, its subsidiaries and its
                affiliates (collectively, “Kodenden,” “we,” “our,” or “us”).
              </h4>

              <h4 className="font-md color-gray mt-4">
                IF YOU HAVE ANY QUESTION OR CONCERN OR NECESSITATE LEGAL ADVICE
                PRIOR TO SIGNING THIS AGREEMENT, YOU MUST REVIEW WITH COUNSEL OF
                YOUR CHOOSING. BY SIGNING THIS AGREEMENT, YOU ACKNOWLEDGE THAT
                YOU UNDERSTAND EACH DISCLOSURE, TERM AND CONDITION AND THE LEGAL
                IMPLICATION OF THE SAME, AND YOU EXPRESSLY WAIVE, RELEASE, AND
                DISCHARGE FROM ANY AND ALL LIABILITY, KODENDEN, LLC, ITS
                SUBSIDIARIES AND ITS AFFILIATES, AND AGREE TO INDEMNIFY, HOLD
                HARMLESS, AND PROMISE NOT TO SUE THE SAME FROM ANY AND ALL
                LIABILITIES OR CLAIMS ARISING FROM THE INSTRUCTIONAL SERVICES
                PROVIDED OR RESULTING FROM A TERM OR CONDITION SET FORTH HEREIN.{" "}
              </h4>

              <h1 className="text-center font-3xl mt-0 mt-sm-5 pt-5">
                Article One
              </h1>
              <h1 className="text-center font-3xl">
                General Terms of Agreement
              </h1>

              <h4 className="mt-4">
                Section 1.01{" "}
                <span
                  className="ms-2 ms-sm-4 font-lg
            "
                >
                  Outline of Services
                </span>
              </h4>
              <p className="font-sm color-gray">
                Kodenden, LLC has developed a program that will provide you with
                hands-on training to teach you how to develop coding skills.
                Kodenden, LLC will also offer you step-by step career coaching.
                The program requires a six-month commitment consisting of
                attending either a live or pre-recorded lecture two times per
                week at a day/time determined by Kodenden, LLC, and completing
                the at- home study course.
              </p>

              <h4 className="mt-4 ms-0 ms-sm-4 ms-lg-5 font-md">
                (a)
                <span className="ms-2 ms-sm-4">Materials</span>
                <p className="mt-3 font-sm me-0 me-md-5 pe-0 pe-sm-5 color-gray">
                  Kodenden, LLC will provide all materials required to complete
                  the course including a laptop containing all necessary
                  software pre-installed. Once sent, the laptop is your sole
                  property and responsibility. Kodenden, LLC is not responsible
                  for any damage or loss of materials. If a loss or damage
                  occurs, you agree to replace the equipment and materials at
                  your own cost. Kodenden, LLC may charge a reasonable fee for
                  re-installation of software as determined in their sole and
                  absolute discretion.
                </p>
              </h4>
              <h4 className="mt-4 ms-0 ms-sm-4 ms-lg-5 font-md">
                (b)
                <span
                  className="ms-2 ms-sm-4
            "
                >
                  Trial Period
                </span>
                <p className="mt-3 font-sm me-0 me-md-5 pe-0 pe-sm-5 color-gray">
                  Kodenden, LLC gives you a 15-day trial period. You may cancel
                  this Agreement by providing written notice within 15 days of
                  accepting this Agreement and the return of all martials. You
                  will receive 100% of your payment back, less shipping and any
                  cost for damaged materials. Payments will be refunded within
                  15 days of receipt of all materials. If materials are not
                  received by the 20th day of signing this agreement, the Trial
                  Period will have expired and you will be responsible for the
                  full contract price.
                </p>
              </h4>
              <h4 className="mt-4 ms-0 ms-sm-4 ms-lg-5 font-md">
                (c)
                <span
                  className="ms-2 ms-sm-4
            "
                >
                  Limited License, Permitted Use
                </span>
                <p className="mt-3 font-sm me-0 me-md-5 pe-0 pe-sm-5 color-gray">
                  You must provide all equipment necessary for your own Internet
                  connection and provide for your own access to the Internet.
                  Subject to the terms and conditions set forth herein, you are
                  hereby granted a limited, non-exclusive, non-transferable,
                  restricted, and revocable right and license to access and use
                  the program strictly in accordance with this Agreement and use
                  the program solely for personal, non-commercial purposes.
                </p>
              </h4>
              <h4 className="mt-4 ms-0 ms-sm-4 ms-lg-5 font-md">
                (d)
                <span
                  className="ms-2 ms-sm-4
            "
                >
                  Nontransferable
                </span>
                <p className="mt-3 font-sm me-0 me-md-5 pe-0 pe-sm-5 color-gray">
                  Your right to use the program is not transferable nor
                  assignable.
                </p>
              </h4>

              <h1 className="text-center font-3xl mt-5">Article Two</h1>
              <h1 className="text-center font-3xl">
                Conditions and Restrictions
              </h1>

              <h4 className="mt-4 font-xl">
                Section 2.01
                <span
                  className="ms-2 ms-sm-4 font-lg
            "
                >
                  Outline of Services
                </span>
              </h4>
              <p className="font-sm  color-gray">
                Your license for access and use of the program is subject to the
                following restrictions and prohibitions on use: (a) you may not
                make unauthorized copies of any of its content or distribute any
                of its content to anyone without permission; and (b) you also
                may not use any of the content in any manner that may infringe
                any copyright, intellectual property right, proprietary right,
                or privacy right of us or any third parties. You assume all
                knowledge of applicable law and are responsible for compliance
                with any such laws. You shall not use the program in any way
                that violates applicable state, federal, or international laws,
                regulations or other government requirements. You further agree
                not to transmit any material that encourages conduct that could
                constitute a criminal offense, give rise to civil liability, or
                otherwise violates any applicable local, state, national or
                international law or regulation.{" "}
              </p>

              <h4 className="mt-4 font-lg">
                Section 2.02
                <span
                  className="ms-2 ms-sm-4
            "
                >
                  Your Representations and Warranties
                </span>
              </h4>
              <p className="font-sm color-gray">
                Your license for access and use of the program is subject to the
                following restrictions and prohibitions on use: (a) you may not
                make unauthorized copies of any of its content or distribute any
                of its content to anyone; (b) you represent and warrant that you
                have the full power and authority to enter into and perform your
                obligations under this Agreement; (c) your assent to and
                performance of your obligations under this Agreement do not
                constitute a breach of or conflict with any other agreement or
                arrangement by which you are bound, or any applicable laws,
                regulations or rules; (d) this Agreement constitutes legal,
                valid and binding obligations on you that is enforceable in
                accordance with its terms and conditions; (e) you will not
                infringe the patent, copyright, trademark, trade secret,
                privacy, right of publicity or other intellectual property or
                proprietary right of Kodenden, LLC or any third party in your
                use of the program; and (f) you understand and acknowledge that
                Kodenden, LLC may terminate this Agreement as it pertains to you
                at any time in its sole discretion.
              </p>

              <h4 className="mt-4 font-lg">
                Section 2.03
                <span
                  className="ms-2 ms-sm-4
            "
                >
                  Errors, Corrections and Changes
                </span>
              </h4>
              <p className="font-sm  color-gray">
                We do not represent or warrant that our program will be
                error-free, free of viruses or other harmful components, or that
                defects will be corrected. We do not represent or warrant that
                the information available on or through the program will be 100%
                correct, accurate, timely or otherwise reliable. We may make
                changes to the features, functionality or content of the program
                at any time. We reserve the right in our sole discretion to edit
                or delete any information or other content.
              </p>

              <h4 className="mt-4 ms-0 ms-sm-4 ms-lg-5 font-md">
                (a)
                <span
                  className="ms-2 ms-sm-4
            "
                >
                  Third Party Content
                </span>
                <p className="mt-3 font-sm me-0 me-md-5 pe-0 pe-sm-5 color-gray">
                  Third-party content may appear on our program or may be
                  accessible via links from our Website. We are not responsible
                  for and assume no liability for any mistakes, misstatements of
                  law, defamation, omissions, falsehood, obscenity, pornography
                  or profanity in the statements, opinions, representations or
                  any other form of content on the Website or our program
                  provided by Third-Parties. You understand that the information
                  and opinions in the third-party content represent solely the
                  thoughts of the author and is neither endorsed by nor does it
                  necessarily reflect Kodenden, LLC’s beliefs.
                </p>
              </h4>

              <h1 className="text-center font-3xl mt-5">Article Three</h1>
              <h1 className="text-center font-3xl">Miscellaneous</h1>

              <h4 className="mt-4 font-lg">
                Section 3.01
                <span
                  className="ms-2 ms-sm-4
            "
                >
                  Liability Limitation
                </span>
              </h4>
              <p className="font-sm  color-gray">
                Kodenden, LLC shall not be liable for any loss, injury, claim,
                liability, or damage of any kind resulting in any way from (a)
                any errors in or omissions from the program or any products
                obtainable therefrom; (b) the unavailability or interruption of
                any features thereof; (c) your use of the program; (d) Content
                contained on the program; or (e) any delay or failure in
                performance beyond the control of Kodenden, LLC.
              </p>

              <h4 className="mt-4 ms-0 ms-sm-4 ms-lg-5 font-md">
                (a)
                <span
                  className="ms-2 ms-sm-4
            "
                >
                  Indemnification
                </span>
                <p className="mt-3 font-sm me-0 me-md-5 pe-0 pe-sm-5 color-gray">
                  You agree to indemnify, defend and hold harmless Kodenden, LLC
                  and our partners, agents, officers, directors, managers,
                  employees, successors, and assigns from any liability, loss,
                  claim, demand, and expense, including reasonable attorneys’
                  fees, related to your violation of this Agreement or use of
                  the program, including any infringement by you of any
                  intellectual property or any other right of any person or
                  entity.
                </p>
              </h4>
              <h4 className="mt-4 ms-0 ms-sm-4 ms-lg-5 font-md">
                (b)
                <span
                  className="ms-2 ms-sm-4
            "
                >
                  Disclaimer
                </span>
                <p className="mt-3 font-sm me-0 me-md-5 pe-0 pe-sm-5 color-gray">
                  THE INFORMATION, CONTENT, AND DOCUMENTS AVAILABLE FROM OR
                  THROUGH THE PROGRAM ARE PROVIDED “AS-IS.” TO THE FULLEST
                  EXTENT PERMITTED BY NEVADA LAW, KODENDEN, LLC MAKES NO
                  REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED,
                  THAT THE PROGRAM OR THE USE THEREOF WILL BE FREE FROM DEFECTS,
                  BUGS, INACCURACIES, ERRORS, OR OTHER LIMITATIONS; OR WILL BE
                  UNINTERRUPTED OR SECURE; OR WILL MEET YOUR REQUIREMENTS.
                  KODENDEN, LLC DOES NOT MAKE ANY QUARANTEE OF EMPLOYMENT, USER
                  ABILITY OR OTHER PERFORMANCE ASSURANCE OF THE LIKE. KODENDEN,
                  LLC IS NOT LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL OR
                  CONSEQUENTIAL DAMAGES (INCLUDING DAMAGES FOR LOSS OF BUSINESS,
                  LOSS OF PROFITS, LITIGATION, OR THE LIKE), WHETHER BASED ON
                  BREACH OF CONTRACT, BREACH OF WARRANTY, TORT (INCLUDING
                  NEGLIGENCE), PRODUCT LIABILITY OR OTHERWISE, EVEN IF ADVISED
                  OF THE POSSIBILITY OF SUCH DAMAGES. THE REPUDIATION AND
                  LIMITATION OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS
                  OF THE BASIS OF THE BARGAIN BETWEEN YOU AND KODENDEN, LLC.
                  THIS PROGRAM PRESENTED WOULD NOT BE PROVIDED WITHOUT SUCH
                  LIMITATIONS. NO ADVICE OR INFORMATION, WHETHER ORAL OR
                  WRITTEN, OBTAINED BY YOU FROM US SHALL CREATE ANY WARRANTY,
                  REPRESENTATION OR GUARANTEE NOT EXPRESSLY STATED IN THIS
                  AGREEMENT.
                </p>
              </h4>

              <h4 className="mt-4 font-lg">
                Section 3.02
                <span
                  className="ms-2 ms-sm-4
            "
                >
                  Agreement Binding
                </span>
              </h4>
              <p className="font-sm  color-gray">
                This Agreement binds you, your legal representatives,
                successors, and assigns, the Personal Representative of any
                deceased Member, and all other parties.
              </p>

              <h4 className="mt-4 font-lg">
                Section 3.03
                <span
                  className="ms-2 ms-sm-4
            "
                >
                  Governing State Law
                </span>
              </h4>
              <p className="font-sm  color-gray">
                This Agreement and all collateral matters will be construed
                according to the laws of the State of Nevada.
              </p>

              <h4 className="mt-4 font-lg">
                Section 3.04
                <span
                  className="ms-2 ms-sm-4
            "
                >
                  Jurisdiction and Venue
                </span>
              </h4>
              <p className="font-sm mb-0 color-gray">
                The parties agree that the Second Judicial District Court of
                Nevada, Washoe County has exclusive jurisdiction, including in
                personam jurisdiction, and will be the exclusive venue for all
                controversies and claims arising out of or relating to this
                Agreement or a breach thereof.
              </p>
              <p className="font-sm color-gray">
                EACH PARTY UNDERSTANDS THE CONSTITUTIONAL RIGHT OF DUE PROCESS
                THAT GUARANTEES THAT EACH PARTY MUST HAVE MINIMUM CONTACTS WITH
                THE STATE OF NEVADA PRIOR TO THE COURT’S EXERCISE OF IN PERSONAM
                JURISDICTION OVER ANY PARTY AND EACH PARTY SPECIFICALLY WAIVES
                THIS CONSTITUTIONAL RIGHT.
              </p>

              <h4 className="mt-4 font-lg">
                Section 3.05
                <span
                  className="ms-2 ms-sm-4
            "
                >
                  Specific Performance
                </span>
              </h4>
              <p className="font-sm mb-0 color-gray">
                If any dispute arises regarding the rights and obligations of
                the parties to this Agreement in violation of the terms of this
                Agreement, the parties agree that they will be irreparably
                harmed. Therefore, the parties agree that this Agreement may be
                specifically enforced, and the conduct enjoined by decree of a
                court having jurisdiction over the subject matter and the
                parties to the controversy. The remedies provided by this
                Section are in addition to any other remedies that the parties
                to this Agreement may have.
              </p>

              <h4 className="mt-4 font-lg">
                Section 3.06
                <span
                  className="ms-2 ms-sm-4
            "
                >
                  Attorneys’ Fees
                </span>
              </h4>
              <p className="font-sm mb-0 color-gray">
                If any party begins any action or proceeding against another
                party in order to enforce the provisions of this Agreement or to
                recover damages as the result of the alleged breach of any of
                the provisions of this Agreement, the prevailing party will be
                entitled to recover all reasonable costs incurred in connection
                with the action or proceeding, including reasonable attorneys’
                fees.
              </p>

              <h4 className="mt-4 font-lg">
                Section 3.07
                <span
                  className="ms-2 ms-sm-4
            "
                >
                  Severability
                </span>
              </h4>
              <p className="font-sm mb-0 color-gray">
                If any provision of this Agreement becomes or is found to be
                illegal or unenforceable for any reason, the clause or provision
                must first be modified to the extent necessary to make this
                Agreement legal and enforceable and then, only if necessary,
                severed from the Agreement to allow the remainder of the
                Agreement to remain in full force.
              </p>

              <h4 className="mt-4 font-lg">
                Section 3.08
                <span
                  className="ms-2 ms-sm-4
            "
                >
                  Captions
                </span>
              </h4>
              <p className="font-sm mb-0 color-gray">
                All captions, titles, headings, and divisions in this document
                are for purposes of convenience and reference only, and must not
                be construed to limit or affect the interpretation of this
                Agreement.
              </p>

              <h4 className="mt-4 font-lg">
                Section 3.09
                <span
                  className="ms-2 ms-sm-4
            "
                >
                  Representation by Legal Counsel
                </span>
              </h4>
              <p className="font-sm mb-0 color-gray">
                Every Party to this Agreement specifically acknowledges that the
                Agreement was prepared by legal counsel representing the
                Company. Further, each party acknowledges that this legal
                counsel was acting solely on behalf of the Company.
              </p>
              <p className="font-sm color-gray">
                EACH PARTY HAS BEEN ADVISED TO SEEK INDEPENDENT LEGAL AND
                FINANCIAL COUNSEL WITH RESPECT TO THIER SIGNING OF THE AGREEMENT
                AND HAS HAD THE OPPORTUNITY TO DO SO.
              </p>

              <h4 className="mt-4 font-lg">
                Section 3.10
                <span
                  className="ms-2 ms-sm-4
            "
                >
                  Acceptance and Termination
                </span>
              </h4>
              <p className="font-sm mb-0 color-gray">
                By accessing and using the Product, you hereby expressly
                acknowledge and agree to be bound by the terms and conditions of
                this Agreement, and any future amendments and additions to this
                Agreement as published from time to time on the Website.
                Kodenden, LLC reserves the right, at any time, to modify, alter,
                or update the terms and conditions of this Agreement without
                prior notice to you. MODIFICATIONS SHALL BECOME EFFECTIVE
                IMMEDIATELY UPON BEING POSTED ON ITS WEBSITE. Your continued use
                of the Program after modifications to this Agreement are posted
                constitutes an acknowledgment and acceptance of the Agreement
                and its modifications.
              </p>
              <p className="font-sm color-gray mb-0">
                This Agreement terminates on the earlier of your completion of
                the program or your termination from the program, whether
                voluntary or involuntary.
              </p>
              <p className="font-sm color-gray">
                This Agreement constitutes the entire agreement between you and
                us, and supersedes all prior or contemporaneous agreements. The
                latest Agreement will be posted on our Website, and you should
                review this Agreement prior to using the Program and should
                continue to review throughout your use of the program.
              </p>
              <p className="fw-bold font-sm mb-5">
                If you do not agree to the terms and conditions of this
                Agreement, you have 15 days to notify us in writing after
                beginning the program. Upon the expiration of 15 days, you have
                expressly accepted and assented to each and every provision of
                this Agreement.{" "}
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TermsAndConditions;
