import React, { useState } from "react";
import leftAnimatedArrowImg from "../assets/img/svg/animated-left-arrow-icon.svg";
import leftDownAnimatedArrowImg from "../assets/img/svg/left-down-arrow-animated.svg";
import CommonHeading from "../components/common/CommonHeading";
import KlaviyoData from "../views/KlaviyoData";

const HowItWorks = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    window.location.reload(true);
  };
  const handleShow = () => setShow(true);
  return (
    <>
      <section className="how-it-works-bg overflow-hidden" id="works">
        <div className="container container_modified pt-5 py-md-5 overflow-hidden">
          <div className="row">
            <div
              className="col-12 text-center my-3 pb-3"
              data-aos="fade-down"
              data-aos-duration="1000"
            >
              <CommonHeading commonHeading="How It Works" />
            </div>
            <div className="col-12 mb-5">
              <div className="row justify-content-center justify-content-lg-between">
                <div
                  className="col-12 col-md-9 col-lg-4 position-relative"
                  data-aos="fade-right"
                  data-aos-duration="1000"
                >
                  <h3 className="font-4xl fw-semibold color-gray mb-0">01</h3>
                  <p className="font-lg fw-bold color-white mb-0">
                    Book Discovery Call
                  </p>
                  <p className="font-xs para mb-0 custom-lineheight col-xl-10 col-xxl-8">
                    Chat with our team to make sure the program is right for
                    you.
                  </p>

                  {/* ANIMATED IMAGE LEFT-DOWN ARROW */}
                  <img
                    className="position-absolute work-arrow-img1"
                    src={leftDownAnimatedArrowImg}
                    alt="leftDownAnimatedArrowImg"
                  />
                </div>

                <div
                  className="col-10 col-md-8 col-lg-4 position-relative"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="500"
                >
                  <div className="d-flex flex-column justify-content-center justify-content-lg-end how-it-works-2 py-5 py-lg-0 my-5 my-lg-0 ms-md-5 ms-lg-0 ps-5 ps-lg-0">
                    <h3 className="font-4xl fw-semibold color-gray mb-0">02</h3>
                    <p className="font-lg fw-bold color-white mb-0">
                      Put in the Work
                    </p>
                    <p className="font-xs para mb-0 custom-lineheight pe-xxl-5 me-xxl-4">
                      We provide a weekly live coding Q&A session. You complete
                      approx. 10-15 hours of exercises per week to sharpen your
                      skills.
                    </p>
                  </div>

                  {/* ANIMATED IMAGE RIGHT-UP ARROW */}
                  <img
                    className="position-absolute work-arrow-img2"
                    src={leftAnimatedArrowImg}
                    alt="leftAnimatedArrowImg"
                  />
                </div>

                <div
                  className="col-12 col-md-9 col-lg-3"
                  data-aos="fade-left"
                  data-aos-duration="1000"
                >
                  <h3 className="font-4xl fw-semibold color-gray mb-0">03</h3>
                  <p className="font-lg fw-bold color-white mb-0">
                    Become a Signed Software Engineer
                  </p>
                  <p className="font-xs para mb-0 custom-lineheight pe-xxl-5">
                    Get step-by-step help landing your first internship or job
                    from a hiring manager still working in the field.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center">
            <button
              onClick={handleShow}
              className=" d-inline-block text-uppercase common-btn  position-relative my-3 "
            >
              <span className="d-flex align-items-center">Learn More</span>
            </button>
          </div>
        </div>
      </section>
      <KlaviyoData handleClose={handleClose} show={show} />
    </>
  );
};

export default HowItWorks;
