import React from "react";
import logo from "../../assets/img/png/logo.png";
import motionsvg from "../../assets/img/svg/motion-svg-arrow-icon.svg";

const OurGuarantee = () => {
  return (
    <div className="py-lg-5 position-relative overflow-hidden">
      <img
        className="position-absolute guarantee_arow d-none d-lg-block"
        src={motionsvg}
        alt="svg "
      />
      <div className="container container_modified py-sm-5 py-4">
        <div className="row justify-content-lg-between justify-content-center">
          <div
            data-aos="fade-right"
            data-aos-delay="300"
            data-aos-duration="800"
            className="col-12 col-lg-6 color-lightgrey mt-4 mt-lg-0 d-flex flex-column justify-content-center text-center text-lg-start"
          >
            <h2 className="font-3xl">Our Guarantee</h2>
            <p className="font-xl fw-normal color-light-gray pt-2">
              If you decide coding isn’t right for you within 15 days of
              starting the program, we’ll give you your money back.
            </p>
            <p className="color-orange">
              I want to become a software engineer in 6 months!
            </p>
            <div className="mt-sm-4 mt-3 pt-lg-3">
              <a
                href="https://app.squarespacescheduling.com/schedule.php?owner=27771236"
                className="modal-btn common-btn text-decoration-none text-uppercase font-sm"
              >
                Book a call with my team
              </a>
            </div>
          </div>
          <div
            data-aos="fade-left"
            data-aos-delay="300"
            data-aos-duration="800"
            className="col-lg-5 col-sm-8 col-10 pt-5 pt-lg-0"
          >
            <img
              className="cursor-pointer w-100 px-xxl-5"
              src={logo}
              alt="logo"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurGuarantee;
