import courseCardImg3 from "../../assets/img/png/launch-tech-career-img.png";
import courseCardImg1 from "../../assets/img/png/master-coding-img.png";
import courseCardImg2 from "../../assets/img/png/standout-portfolio-img.png";

import programCardImg1 from "../../assets/img/png/program-card-img1.png";
import programCardImg2 from "../../assets/img/png/program-card-img2.png";
import programCardImg3 from "../../assets/img/png/program-card-img3.png";
import programCardImg4 from "../../assets/img/png/program-card-img4.png";
import programCardImg5 from "../../assets/img/png/program-card-img5.png";
import programCardImg6 from "../../assets/img/png/program-card-img6.png";

import liveCoachingImg1 from "../../assets/img/png/live-coaching-img.png";
import moduleImg1 from "../../assets/img/png/module-img-1.png";
import moduleImg2 from "../../assets/img/png/module-img-2.png";
import moduleImg3 from "../../assets/img/png/module-img-3.png";
import moduleImg4 from "../../assets/img/png/module-img-4.png";
import moduleImg5 from "../../assets/img/png/module-img-5.png";
import moduleImg6 from "../../assets/img/png/module-img-6.png";
import traditionalBootcamps from "../../assets/img/png/traditional-bootcamps.png";
import virtualMeetingImg from "../../assets/img/png/virtual-meeting-img.png";

import studentsliderImgOne from "../../assets/img/png/student-slider-icon.png";
import studentsliderImgTwo from "../../assets/img/png/student-slider-icon-2.png";
import studentsliderImgThree from "../../assets/img/png/student-slider-icon-3.png";

import LinkedInImg from "../../assets/img/png/LinkedIn.png";

// HEADER SECTION NAV-LINKS DATA
export const headerData = [
  {
    navlink: "Home",
    url: "#home",
  },
  // {
  //   navlink: "Rapid Learning",
  //   url: "/rapid-learning",
  // },
  {
    navlink: "About",
    url: "#about",
  },

  {
    navlink: "Program",
    url: "#program",
  },
  {
    navlink: "How it works",
    url: "#works",
  },
  {
    navlink: "FAQs",
    url: "#faqs",
  },
];
// SERVICES SECTION NAV-LINKS DATA
export const servicesData = [
  {
    heading: "  LIVE Small-Group",
    title: " Coaching",
    imgUrl: liveCoachingImg1,
  },
  {
    heading: "Virtual Meetings",
    title: "  That Fit Your Schedule",
    imgUrl: virtualMeetingImg,
  },
  {
    heading: " 1/2 the Price of",
    title: " Traditional Bootcamps",
    imgUrl: traditionalBootcamps,
  },
];

// 01 ABOUT SECTION PARAS
export const aboutItemData = [
  {
    title: `With 6-figure salaries, unlimited PTO, and the flexibility to work anywhere in the world—(or`,
  },
  {
    title: `simply pick your kids up from school), you want in.`,
  },
  {
    title: `But who has $25k+ to drop on a bootcamp?`,
  },
  {
    title: `Or semesters of free time to dedicate to going back to school?`,
  },
  {
    title: `Or YEARS to go through the trial and error involved in teaching yourself?`,
  },
  {
    title: `Heck, most people don’t even know where to start.`,
  },
  {
    title: `If that's you, you’ve been dreaming of a high-paying tech career but paralyzed when it comes time to take action.`,
  },
];

// 01 ABOUT SECTION COURSE CARDS DATA
export const courseData = [
  {
    CourseNo: "01",
    courseCardImg: courseCardImg1,
    CourseHeading: "Master",
    CourseSubHeading: "Full-Stack Coding Skills",
    backContent: `Learn the in-demand skills top developers use every day to plan, build, and maintain mobile apps and websites used by billions. We’ll cover everything from computer science fundamentals to cutting-edge tools like React`,
  },
  {
    CourseNo: "02",
    courseCardImg: courseCardImg2,
    CourseHeading: "Build a",
    CourseSubHeading: "Standout Portfolio",
    backContent:
      "Pack your portfolio with 6 hire-worthy projects that will separate you from the pack and prove you’ve got the skills today’s companies need. Websites, apps, and APIs included.",
  },
  {
    CourseNo: "03",
    courseCardImg: courseCardImg3,
    CourseHeading: "Launch",
    CourseSubHeading: "Your Tech Career",
    backContent:
      "Get step-by-step career guidance from a hiring manager still working in the industry so you can … Crush your interviews, successfully negotiate your salary, and climb the ranks with confidence.",
  },
];

// 02 TESTIMINALS SECTION SLIDER CARDS DATA
export const sliderData = [
  {
    heading: `Harold Tourjee, III, Kodeden Student`,
    desc: `Kody has been super accessible and helpful when I don't understand a concept and he gets me right back on track. He helps build not only programming skills but skills for studying, soft skills for interviews, and confidence to be able to use these skills. Can't wait to finish the program and move on to a programming career.`,
    imgUrl: null,
    name: "Harold Tourjee, III",
    nameDesc: "",
    link: null,
  },
  {
    heading: `Jeremy Eastman, Kodeden Student`,
    desc: `Kodeden has been truly amazing. It has opened my eyes to a new kind of fun and rewarding experience and the potential for a rewarding career!`,
    imgUrl: null,
    name: "Jeremy Eastman",
    nameDesc: "",
    link: null,
  },

  {
    heading: `"Luke O'Malley 
    Tech Lead Agile Onboarding."`,
    desc: `I highly recommend Kody Doherty. I have had the pleasure of working with him on a variety of software projects over the past three years and his guidance has been integral to my personal success. He is a driven, organized engineer who develops inspiring relationships with his students.
    Kody has broad knowledge of the entire software development lifecycle, from project management all the way down to the packets sent over the wire and everything in between. Kody also has excellent problem solving skills and is great at getting to the core of the issue. He takes a humble approach to learning and always shows a lot of interest in those he is working with.
    Kody has a wonderful rapport with people. He has a talent for breaking down complex material and explaining it in a concise and easy to understand way. I recommend Kody as a mentor without reservation. If you have any further questions with regard to his background or qualifications, please do not hesitate to contact me.`,
    imgUrl: null,
    name: "Luke O'Malley",
    nameDesc: "@Luke O'Malley",
    link: "https://www.linkedin.com/in/lukeomalley7/",
  },
  {
    heading: `Jeremy Eastman, Kodeden Student`,
    desc: `Kodeden has been truly amazing. It has opened my eyes to a new kind of fun and rewarding experience and the potential for a rewarding career!`,
    imgUrl: null,
    name: "Jeremy Eastman",
    nameDesc: "",
    link: null,
  },
];

// 03 INSTRUCTORS SECTION PARAS
export const instructionsParaData = [
  {
    instructionsPara:
      "And we know firsthand that building a career as a software engineer without a computer science degree is possible.",
  },
  {
    instructionsPara:
      "   We started where you are *right now*—without engineering degrees, a professional portfolio, or years of experience.",
  },
  {
    instructionsPara:
      " Then we climbed the ranks to hold senior positions at tech titans like Amazon, Apple, and the US government.",
  },
  {
    instructionsPara:
      "Throughout our careers, we’ve managed entire teams of engineers, directed $30 million program budgets, and built 2 successful tech startups (one that we sold).",
  },
  {
    instructionsPara:
      "  Learning to code did more than add a few impressive lines to our resumes. It literally changed our lives…",
  },
];

// 04 PROGRAM SECTION CARDS DATA
export const programData = [
  {
    programPara: "Private coaching with an industry expert.",
  },
  {
    programPara: "Daily accountability to make sure you stay on track.",
  },
  {
    programPara:
      "Weekly live coding Q&A sessions to ask questions and get help.",
  },
  {
    programPara: "Hands-on practical content, so no death by PowerPoint.",
  },

  {
    programPara:
      "10-15 hours a week worth of challenges and projects to hone your skills.",
  },
  {
    programPara:
      "You will complete a portfolio of projects you can use to showcase your skills to a future employer.",
  },
  {
    programPara:
      "Feedback on all your assignments from experts working in the industry. This critical step will improve your skills rapidly.",
  },
  {
    programPara:
      "We teach you the skills to not just learn to code but to be able to hit the ground running as a well-rounded Software Engineer.",
  },

  {
    programPara:
      "We teach you the productivity hacks to make you beat the competition.",
  },
  {
    programPara:
      "We help you crush your technical interview with our mock interviews and coding challenges.",
  },
  {
    programPara:
      "We help you craft a fantastic resume from our experience reviewing hundreds of Software Engineering resumes over the years.",
  },
  {
    programPara: "One year of access to the material.",
  },

  {
    programPara:
      "You will gain access to our KodeDen community of like-minded Software Engineers to skyrocket your professional network.",
  },
];

// 05 MODULES SECTION ROADMAP DATA
export const moduleData = [
  {
    color: "var(--lightpurple)",
    moduleImg: moduleImg1,
    moduleNo: "1st",
    moduleMonth: "Month",
    moduleheading: "Module 1",
    modulesubheading: "HTML/CSS and Modern Design",
    moduleparaMain: "Sub Heading",
    moduleparaMain2: "Sub Heading2",
    modulepara:
      "This module will teach you how to build a website from scratch! You will learn modern web design and advanced CSS to create websites rapidly.",
  },
  {
    color: "var(--lightgreen)",
    moduleImg: moduleImg2,
    moduleNo: "2nd",
    moduleMonth: "Month",
    moduleheading: "Module 2",
    modulesubheading: "Javascript",
    moduleparaMain: "Sub Heading",
    moduleparaMain2: "Sub Heading2",
    modulepara:
      "Here you will learn the basics of Javascript and build a command-line (CLI) tool to perform some automation! In this module, we will also start interview prep.",
  },

  {
    color: "var(--lightpink)",
    moduleImg: moduleImg3,
    moduleNo: "3th",
    moduleMonth: "Month",
    moduleheading: "Module 3",
    modulesubheading: "Web Application Development with React",
    moduleparaMain: "Sub Heading",
    moduleparaMain2: "Sub Heading2",
    modulepara:
      "In this module, you will learn how to make websites come alive by building dynamic web components using the industry-leading JavaScript Framework React.",
  },
  {
    color: "var(--lightnavy)",
    moduleImg: moduleImg4,
    moduleNo: "4th",
    moduleMonth: "Month",
    moduleheading: "Module 4",
    modulesubheading:
      "Web Application Development with Advanced React and TypeScript",
    moduleparaMain: "Sub Heading",
    moduleparaMain2: "Sub Heading2",
    modulepara:
      "In module 4, we will cover advanced topics with React so you can build large-scale web applications.  We cover state management with Redux, TypeScript, Advanced React, and much more!",
  },
  {
    color: "var(--lightskincolor)",
    moduleImg: moduleImg5,
    moduleNo: "5rd",
    moduleMonth: "Month",
    moduleheading: "Module 5",
    modulesubheading: "REST API Development with Node, Express, and SQL",
    moduleparaMain: "Sub Heading",
    moduleparaMain2: "Sub Heading2",
    modulepara:
      "This module will teach you what is under the hood and how you can build a web service from scratch.",
  },
  {
    color: "var(--lightaqua)",
    moduleImg: moduleImg6,
    moduleNo: "6th",
    moduleMonth: "Month",
    moduleheading: "Module 6",
    modulesubheading: "Operational Skills with AWS, Linux, and Bash",
    moduleparaMain: "Sub Heading",
    moduleparaMain2: "Sub Heading2",
    modulepara:
      "In the final module, we will teach you how to deploy your web applications in the AWS Cloud.",
  },
];

// FAQ SECTION DATA
export const faqData = [
  {
    key: "1",
    title:
      "Software Engineering looks overwhelming, and I have tried to learn it myself in the past. What makes this time different?",
    desc: "We have built this program for people with no prior experience and have simplified the content enough so anyone can pick it up. In addition, we teach you how to develop new skills rapidly. This meta-skill will make learning new skills feel like cheating because you will pick them up fast!",
  },
  {
    key: "2",
    title: "What are starting salaries for a Software Engineer?",
    desc: "According to indeed.com, your average salary is around $74k in the United States, and the highest-paid cities can get up to 90k. With one to two years of experience under your belt, you can quickly jump to six-figure incomes. ",
  },
  {
    key: "3",
    title:
      "I get nervous in interviews, and it is holding me back. How does your program help? ",
    desc: "We not only help you gain the expertise to crush your interview, but we also provide mock interviews for you to build confidence through practice. We teach you the industry tricks to stand out!",
  },
  {
    key: "4",
    title: "Do you help with resumes and Linkedin profiles?",
    desc: "Yes! We have resume writers and industry experts to assist you in crafting a stand-out resume and Linkedin profile.",
  },
  {
    key: "5",
    title:
      "What is the difference between Kodeden's program and a Coding Bootcamp? ",
    desc: "Our instructors work in the industry and know what hiring managers are looking for in candidates. We teach you the hands-on skills required to get a job as a Software Engineer and the soft skills necessary to excel in the industry. ",
  },
  {
    key: "6",
    title: "How many hours a week should I plan to work on this program?",
    desc: "You are looking at about 10+ hours a week of hands-on practice.",
  },
  {
    key: "7",
    title:
      "What time of days are the lectures, and how many of them are there a week? ",
    desc: "We have at least two recorded lectures a week and one live Q&A session in the late afternoon or evenings, depending on your time zone. We are based in the United States. We will help you plan out your weekly schedule so you can have protective time to meet your 10+ hours of week of study. ",
  },
  {
    key: "8",
    title: "What happens if I miss a session?",
    desc: "We record all of our Zoom sessions and provide you with a copy of the recording so you can stay up with the rest of the cohort. ",
  },
  {
    key: "9",
    title: "What kind of computer do I need to get started? ",
    desc: "We highly recommend you use an Apple MacBook or MacBook Pro with at least 8GB of ram.",
  },
];
// ===aksnkdsmlm
// OUR STUDENTS SLIDER SECTION DATA
export const studentSliderData = [
  {
    heading: "“Luke O’Malley Tech Lead Agile Onboarding.”",
    para: "I highly recommend kody Doherty. I have had the pleasure of working with him on variety of software projects over the past three years and his guidance has been intergral to my personal success. He is a driven, organized engineer who develops inspiring relationships with his students. ",
    btntext: "Read More...",
    writerName: "Luke O’Malley",
    writertext: "@Luke O’Malley",
    imgUrl: studentsliderImgTwo,
    imgUrl2: LinkedInImg,
  },
];
