import React from "react";

const IncludedCardsData = ({ item, id }) => {
  return (
    <>
      <div
        className={`col-12 col-sm-6 col-md-4 mt-3 pt-3 mt-md-0 pt-md-0 ${
          id > 2 ? "mt-md-3 pt-md-3" : ""
        }`}
        data-aos="zoom-in"
        data-aos-duration="1000"
      >
        <div className="included-card-parent p-3 h-100">
          {id % 2 === 0 ? (
            <img
              className="mb-1 mb-sm-3"
              src={item.imgUrl}
              alt="course-included-card-img"
              id="course-img"
            />
          ) : (
            <img
              className="mb-1 mb-sm-3 float-animation"
              src={item.imgUrl}
              alt="course-included-card-img"
            />
          )}
          <h4 className="font-xl fw-normal color-lightgrey text-capitalize mb-1">
            {item.heading}
          </h4>
          <p className="para mb-0">{item.para}</p>
        </div>
      </div>
    </>
  );
};

export default IncludedCardsData;
