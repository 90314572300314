import React from "react";
import { useMediaQuery } from "react-responsive";
import logo from "../../assets/img/png/logo.png";
import { headerData } from "./Helper";
import { useLocation, useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

const Header = () => {
  const BeforeDesktop = ({ children }) => {
    const isBeforeDesktop = useMediaQuery({ maxWidth: 991.98 });
    return isBeforeDesktop ? children : null;
  };
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    return isDesktop ? children : null;
  };
  const handldeOverlayActive = () => {
    document.body.classList.toggle("active-nav-overlay");
  };
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      <p className="font-xs color-black bg-white text-center py-2 mb-0 header-fixed-line">
        “Applications for the May 2023 cohort are now open”
      </p>
      <div className="navbar-wrapper w-100 pt-5">
        {/* overflow-hidden */}
        <Desktop>
          <div
            className={` ${
              location.pathname === "/rapid-learning" && "container_modified"
            } container d-flex justify-content-between align-items-center`}
            data-aos="fade-right"
            data-aos-delay="400"
          >
            <div className="w-100">
              <img
                onClick={() => navigate("/")}
                className="cursor-pointer logo"
                src={logo}
                alt="logo"
              />
            </div>
            <div className="nav-list-items w-100 d-flex align-items-center">
              <ul className="list-unstyled d-flex align-items-center m-0">
                {headerData.map((item, index) => (
                  <li className="pe-4 me-xl-2 text-nowrap" key={index}>
                    <a
                      className={`text-decoration-none navbar-link ${
                        (location.pathname === "/" && index === 0) ||
                        (location.pathname === "/rapid-learning" && index === 1)
                          ? "color-orange"
                          : ""
                      }`}
                      href={`${
                        location.pathname === "/rapid-learning"
                          ? `/${item.url}`
                          : `${item.url}`
                      }`}
                    >
                      {item.navlink}
                    </a>
                  </li>
                ))}
              </ul>
              <Dropdown className="text-decoration-none navbar-link me-4">
                <Dropdown.Toggle
                  className="bg-transparent border-0 p-0 font-sm fw-medium color-gray"
                  id="dropdown-basic"
                >
                  Courses
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="/courses">
                    HTML/CSS And Modern Website Design
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <a
                href="https://app.squarespacescheduling.com/schedule.php?owner=27771236"
                className="common-btn position-relative text-nowrap text-decoration-none ms-2"
              >
                Get Started
              </a>
            </div>
          </div>
        </Desktop>
        {/* OVERLAY HERE  THIS CODE RUN BEFORE 992PX*/}
        <BeforeDesktop>
          <div className="px-3 d-flex justify-content-between align-items-center">
            <div>
              <img className="cursor-pointer logo" src={logo} alt="logo" />
            </div>

            <div className="d-none d-sm-block">
              <a
                href="https://app.squarespacescheduling.com/schedule.php?owner=27771236"
                className="common-btn position-relative text-decoration-none"
              >
                Get Started
              </a>
            </div>

            <div
              id="nav-icon1"
              className={`hamburger-menu`}
              onClick={() => handldeOverlayActive()}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          <div className={`navbar-overlay`}>
            <ul className="h-100 list-unstyled d-flex flex-column align-items-center justify-content-center m-0">
              {headerData.map((item, index) => (
                <li
                  className="py-2 my-sm-1 mt-2"
                  onClick={() => handldeOverlayActive()}
                  key={index}
                >
                  <a
                    className=" navbar-link text-decoration-none "
                    href={`${
                      location.pathname === "/rapid-learning"
                        ? `/${item.url}`
                        : `${item.url}`
                    }`}
                  >
                    {item.navlink}
                  </a>
                </li>
              ))}
              <li
                className="py-2 my-sm-1 mt-4 d-block d-sm-none"
                onClick={() => handldeOverlayActive()}
              >
                <a
                  href="https://app.squarespacescheduling.com/schedule.php?owner=27771236"
                  className="common-btn position-relative text-decoration-none font_xs_16"
                >
                  Get Started
                </a>
              </li>
            </ul>
          </div>
        </BeforeDesktop>
      </div>
    </>
  );
};

export default Header;
