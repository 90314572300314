import React, { useState } from "react";
import moduleOpenBtnIcon from "../../assets/img/svg/right-arrow-btn-icon-img.svg";
import ModuleModal from "./ModuleModal";

const ModuleItem2 = ({ id, item }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);
  return (
    <div
      className={` ${
        id <= 2 ? "custom-module-items custom-module-padding" : ""
      }`}
    >
      <div className="row align-items-center ms-1 ms-lg-5 module-card position-relative mb-5 me-1 me-sm-0">
        <div className="col-2 col-sm-3 col-lg-4 position-relative d-flex align-items-center justify-content-center d-none d-sm-block">
          <img
            className="module-img d-none d-lg-block"
            src={item.moduleImg}
            alt="moduleImg1"
          />
          <div className="module-month-no d-flex align-items-center justify-content-center text-center d-lg-none mx-auto">
            {item.moduleNo}
            <br />
            {item.moduleMonth}
          </div>
        </div>

        <div className="col-12 col-sm-9 col-lg-8 pe-0 module-overflow-hidden py-lg-3 py-xl-0">
          <div className="d-flex align-items-center module-content ps-2 ps-sm-3 py-3 py-sm-0">
            <div>
              <div className="module-month-no d-flex align-items-center justify-content-center text-center d-sm-none mb-2">
                {item.moduleNo}
                <br />
                {item.moduleMonth}
              </div>
              <h6 className="font-md fw-bold color-light-gray pt-3 pt-sm-0 mb-2 module-one-heading">
                {item.moduleheading}
              </h6>
              <h4 className="font-lg fw-bold color-white">
                {item.modulesubheading}
              </h4>
              <p className="para custom-lineheight mb-0 pe-4 pe-lg-5">
                {item.modulepara}
              </p>
              <ModuleModal item={item} show={show} handleClose={handleClose} />
            </div>
            <div
              style={{ backgroundColor: item.color }}
              className="module-btn h-100"
            >
              <button
                onClick={() => {}}
                className="d-flex align-items-center justify-content-center bg-transparent border-0 p-0 m-0"
              >
                <img src={moduleOpenBtnIcon} alt="module_open_btn_icon" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModuleItem2;
