import React from "react";

const CommonHeading = ({ commonHeading }) => {
  return (
    <>
      <h2 className="sub-heading text-capitalize mb-0">{commonHeading}</h2>
    </>
  );
};

export default CommonHeading;
