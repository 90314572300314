import React from "react";
import { Accordion } from "react-bootstrap";
import CommonHeading from "./common/CommonHeading";
import FaqItem from "./common/FaqItem";
import { faqData } from "./common/Helper";

const Faq = () => {
  return (
    <>
      <div className="overflow-hidden">
        <div
          className="container container_modified normal_accordion py-5"
          id="faqs"
        >
          <CommonHeading
            commonHeading="frequently asked questions"
            data-aos="fade-down"
            data-aos-duration="1000"
          />
          <Accordion defaultActiveKey="1" className="pt-4">
            {faqData.map((obj, index) => (
              <FaqItem obj={obj} key={index} id={index} />
            ))}
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default Faq;
