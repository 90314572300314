import React, { useState } from "react";
import CommonPointHeading from "./common/CommonPointHeading";
import { moduleData } from "./common/Helper";
import ModuleItem from "./common/ModuleItem";
import ModuleItem2 from "./common/ModuleItem2";

const Modules = () => {
  const [tabValue1, setTabValue1] = useState(true);
  const [tabValue2, setTabValue2] = useState(false);
  return (
    <>
      <section className="custom-bottom-border position-relative ">
        <div className="container container_modified">
          <div className="row">
            <div className="col-12 description-section">
              <div className="row">
                <CommonPointHeading pointText="Modules" />
                <div className="col-12 col-md-10 pt-4 pt-md-0">
                  <h5 className="d-flex flex-column mb-0  py-2 d-lg-none">
                    <p className="mb-0 para font-lg sidebar_text py-2 px-3 about-heading-text">
                      Modules
                    </p>{" "}
                    <button
                      className={` me-3 fw-semibold mt-2 custom_width_btn ${
                        tabValue1 ? "common-btn btn2" : "border_btn"
                      }`}
                      onClick={() => {
                        setTabValue1(true);
                        setTabValue2(false);
                      }}
                    >
                      Front End Web Developer
                      <span
                        className="opacity-7 fw-normal
                      "
                      >
                        (4 Months)
                      </span>
                    </button>
                    <button
                      className={` fw-semibold custom_width_btn mt-2 mt-lg-0 ${
                        tabValue2 ? "common-btn btn2" : "border_btn"
                      }`}
                      onClick={() => {
                        setTabValue2(true);
                        setTabValue1(false);
                      }}
                    >
                      Full Stack Web Developer program
                      <span
                        className="opacity-7 fw-normal
                      "
                      >
                        (6 Months)
                      </span>
                    </button>
                  </h5>

                  <div className="ps-md-4 ps-xl-5 py-4 py-sm-5 description-content custom-bottom-border2 module_section overflow-hidden">
                    <div className="d-none d-lg-block pb-3">
                      <button
                        className={` me-3 fw-semibold custom_width_btn ${
                          tabValue1 ? "common-btn btn2" : "border_btn"
                        }`}
                        onClick={() => {
                          setTabValue1(true);
                          setTabValue2(false);
                        }}
                      >
                        Front End Web Developer
                        <span
                          className="opacity-7 fw-normal
                      "
                        >
                          (4 Months)
                        </span>
                      </button>
                      <button
                        className={` fw-semibold custom_width_btn mt-3 mt-sm-0 ${
                          tabValue2 ? "common-btn btn2" : "border_btn"
                        }`}
                        onClick={() => {
                          setTabValue2(true);
                          setTabValue1(false);
                        }}
                      >
                        Full Stack Web Developer program
                        <span
                          className="opacity-7 fw-normal
                      "
                        >
                          (6 Months)
                        </span>
                      </button>
                    </div>
                    {tabValue1 && (
                      <div className="pt-5">
                        {moduleData.slice(0, 4).map((item, index) => (
                          <ModuleItem2 item={item} key={index} id={index} />
                        ))}
                      </div>
                    )}

                    {tabValue2 && (
                      <div className="pt-5">
                        {moduleData.map((item, index) => (
                          <ModuleItem item={item} key={index} id={index} />
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Modules;
