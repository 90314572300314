import React, { useState } from "react";
import { evrythingData } from "./Helper";
import CommonHeading from "../common/CommonHeading";
import KlaviyoData from "../../views/KlaviyoData";

const Everything = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    window.location.reload(true);
  };
  const handleShow = () => setShow(true);
  return (
    <div>
      <div className="ps-md-4 ps-xl-5 pt-4 pb-5 py-sm-5 description-content custom-bottom-border2  custom_line">
        <div
          className="mt-3 mb-2"
          data-aos="fade-down"
          data-aos-duration="1000"
        >
          <CommonHeading commonHeading="Everything you need to know to build modern, pixel-perfect web experiences" />
        </div>
        <p
          className="para pb-4"
          data-aos="zoom-in"
          data-aos-delay="200"
          data-aos-duration="1000"
        >
          No fluff. No spiraling. No forgetting everything you’ve learned.
        </p>

        <div className="row justify-content-center">
          {evrythingData &&
            evrythingData.map((item, index) => (
              <div
                className="col-md-4 col-sm-6 col-10 border_75 p-0"
                // item={item}
                key={index}
                id={index}
              >
                {item.everyImg ? (
                  <div className="overflow-hidden h-100">
                    <img
                      src={item.everyImg}
                      alt="monitor"
                      className="w-100 h-100 obj_fit every_img_hover"
                    />
                  </div>
                ) : (
                  ""
                )}
                <div className="px-4 py-5">
                  {item.everyHeading ? (
                    <p className="color-gray fw-medium font-xl color-light-grey mb-1">
                      {item.everyHeading}
                    </p>
                  ) : (
                    ""
                  )}
                  {item.everyApply ? (
                    <p className="mb-0 color-gray fw-normal font-sm lh_23">
                      {item.everyApply}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ))}
        </div>
        <div
          className="d-flex flex-column flex-sm-row align-items-center my-3 my-md-5 pt-sm-3 pt-lg-0 pt-xl-3 every_instructors_btns"
          data-aos="fade-up"
          data-aos-delay="300"
          data-aos-duration="800"
        >
          <button
            onClick={handleShow}
            className="text-nowrap d-inline-block common-btn hero-btn position-relative font-xs course_schedule_btn"
          >
            <span className="d-flex align-items-center fw-bold justify-content-center justify-content-md-start">
              Schedule 15-min Discovery Call
            </span>
          </button>

          <button className="text-nowrap enroll_btn font-xs fw-bold text-white ms-sm-3 d-flex align-items-center justify-content-center mt-2 mt-sm-0">
            <a
              href="https://kodeden.thrivecart.com/kodedens-1-month-htmlcss-workshop/"
              target="_blank"
              className="text-decoration-none text-white hover-text-orange"
            >
              Enroll Now for $500
            </a>
          </button>
        </div>
      </div>
      <KlaviyoData handleClose={handleClose} show={show} />
    </div>
  );
};

export default Everything;
