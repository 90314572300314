import React from "react";
import { Accordion } from "react-bootstrap";

const FaqItem = ({ obj, id }) => {
  return (
    <>
      <Accordion.Item
        eventKey={obj.key}
        data-aos-delay={`${(id + 1) * 200}`}
        data-aos="zoom-in"
        data-aos-duration="1000"
      >
        <Accordion.Header>{obj.title}</Accordion.Header>
        <Accordion.Body className="px-0 pt-0">
          <span className="border_bottom_1px d-inline-block">{obj.desc}</span>
        </Accordion.Body>
      </Accordion.Item>
    </>
  );
};

export default FaqItem;
