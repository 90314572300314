import React from "react";
import CourseImg from "../../assets/img/png/union-course-person-image.png";
import motionsvg from "../../assets/img/svg/motion-svg-arrow-icon.svg";

const SoftwareCourse = () => {
  return (
    <>
      <div className="overflow-hidden">
        <div className="container container_modified pb-5 pt-4">
          <div className="row justify-content-center align-items-center">
            <div
              className="col-12 col-xl-7 pe-xxl-0 order-1 order-xl-0 position-relative"
              data-aos="zoom-in"
              data-aos-duration="800"
              data-aos-delay="300"
            >
              {/* ANIMATED IMAGE RIGHT-UP SIDE ARROW */}
              <img
                className="position-absolute course-arrow   d-none d-lg-block"
                src={motionsvg}
                alt="svg "
              />
              <h2 className="sub-heading">
                What is KodeDen's
                <span className="linear-gradient-orange mx-2">6-Month</span>
                <br className="d-none d-xl-block" />
                Software Engineering Course?
              </h2>
              <ul className="software-course-list">
                <li className="para mb-2 list-item">
                  Private coaching with an industry expert.
                </li>

                <li className="para mb-2 list-item">
                  Daily accountability to make sure you stay on track.
                </li>
                <li className="para mb-2 list-item">
                  Weekly live coding Q&A sessions to ask questions and get help.
                </li>
                <li className="para mb-2 list-item">
                  Hands-on practical content, so no death by PowerPoint.
                </li>
                <li className="para mb-2 list-item">
                  10-15 hours a week worth of challenges and projects to hone
                  your skills.
                </li>
                <li className="para mb-2 list-item">
                  You will complete a portfolio of projects you can use to
                  showcase your skills to a future employer.
                </li>
                <li className="para mb-2 list-item">
                  Feedback on all your assignments from experts working in the
                  industry. This critical step will improve your skills rapidly.
                </li>
                <li className="para mb-2  list-item">
                  We teach you the skills to not just learn to code but to be
                  able to hit the ground running as a well-rounded Software
                  Engineer.
                </li>
                <li className="para mb-2   list-item">
                  We teach you the productivity hacks to make you beat the
                  competition.
                </li>
                <li className="para mb-2   list-item">
                  We help you crush your technical interview with our mock
                  interviews and coding challenges.
                </li>
                <li className="para mb-2   list-item">
                  We help you craft a fantastic resume from our experience
                  reviewing hundreds of Software Engineering resumes over the
                  years.
                </li>
                <li className="para mb-2   list-item">
                  One year of access to the material.
                </li>
                <li className="para mb-2   list-item">
                  You will gain access to our KodeDen community of like-minded
                  Software Engineers to skyrocket your professional network.
                </li>
              </ul>
              <p className="para linear-gradient-orange fw-semibold list-item mb-4 mb-sm-5">
                I want to Start My Software Engineering Journey!
              </p>
              <a
                href="https://app.squarespacescheduling.com/schedule.php?owner=27771236"
                className="modal-btn common-btn text-decoration-none font-sm text-uppercase"
              >
                Book a call with my team
              </a>
            </div>

            <div
              className="col-11 col-sm-12 col-md-7 order-0 order-xl-1 col-xl-5 mb-5 mb-xl-0"
              data-aos="fade-left"
              data-aos-delay="300"
              data-aos-duration="800"
            >
              <img className="w-100" src={CourseImg} alt="CourseImg" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SoftwareCourse;
