import React from "react";
import motionsvg from "../../assets/img/svg/motion-svg-arrow-icon.svg";
import CommonPointHeading from "../common/CommonPointHeading";
import CommonHeading from "../common/CommonHeading";

const Checkout = () => {
  return (
    <>
      {/* 07 */}
      <section
        className="custom-bottom-border position-relative whats_included overflow-y-hidden01"
        id="what-you-learn"
      >
        <div className="container container_modified">
          <div className="row">
            <div className="col-12 description-section">
              <div className="row sidebar_text_parent">
                <CommonPointHeading pointNo="07" pointText="Checkout" />
                <div className="col-12 col-lg-10 pt-5 pt-md-0">
                  <h5 className="d-flex flex-column mb-0 about-heading-text enroll_text py-2 px-3 d-lg-none">
                    <span className="mb-0 para font-lg sidebar_text">
                      <a
                        href="https://kodeden.thrivecart.com/kodedens-1-month-htmlcss-workshop/"
                        target="_blank"
                        className="text-decoration-none text-white hover-text-black"
                      >
                        Checkout
                      </a>
                    </span>
                  </h5>
                  <div className="ps-2 ps-md-4 ps-xl-5 py-4 py-sm-5 description-content custom-bottom-border2 custom_line">
                    <div className="mt-lg-3 mb-lg-5 pb-lg-5">
                      <CommonHeading commonHeading="Ready to reserve your spot?" />
                      <div className="mt-4 mt-sm-5 pt-lg-4 col-xl-9">
                        <a
                          className="text-decoration-none text-nowrap d-inline-block common-btn hero-btn position-relative font-xs course_schedule_btn w-100 mt-lg-2 fw-bold hover-text-black"
                          href="https://kodeden.thrivecart.com/kodedens-1-month-htmlcss-workshop/"
                          target="_blank"
                        >
                          <span className="d-flex align-items-center justify-content-center w-100">
                            Checkout
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ANIMATED IMAGE RIGHT SIDE ARROW */}
        <img
          className="position-absolute d-none d-lg-block checkout-icon"
          src={motionsvg}
          alt="svg "
        />
      </section>
    </>
  );
};

export default Checkout;
