import { useEffect, useLayoutEffect, useRef, useState } from "react";
import CommonHeading from "./common/CommonHeading";
import Testiminals from "./Testiminals";

const TestiminialsSection = ({ number }) => {
  const [leftMargin, setLeftMargin] = useState(0);
  const containerRef = useRef(null);

  useLayoutEffect(() => {
    if (containerRef.current) {
      setLeftMargin(containerRef.current.getBoundingClientRect().left);
    }

    window.addEventListener("resize", () => {
      if (containerRef.current) {
        setLeftMargin(containerRef.current.getBoundingClientRect().left);
      }
    });
  }, [leftMargin]);

  return (
    <>
      <div className="container container_modified" ref={containerRef}></div>
      <section
        className="position-relative"
        style={{ marginLeft: `${leftMargin + 12}px` }}
      >
        <div className="custom_sticky pe-2 pe-sm-0 me-1 me-sm-0 pt-4">
          <h5 className="d-flex flex-column mb-0 about-heading-text py-2 px-3 d-lg-none h-100 mw-516 mx-lg-auto">
            {/* {number} */}
            <span className="mb-0 para font-lg sidebar_text">Testimonials</span>
          </h5>
        </div>
        <div className="row mx-0 ">
          <div className="col-lg-3 col-12 col-xl-2 ps-0 ">
            <div className="description-section h-100 ">
              <div className=" pt-4 py-md-5 mt-md-3 d-none d-lg-block h-100">
                <h5 className="d-flex flex-column mb-0 min-w-400">
                  {/* {number} */}
                  <span className="mt-1 mt-lg-3 mb-0 para font-lg sidebar_text">
                    Testimonials
                  </span>
                </h5>
              </div>
            </div>
          </div>
          <div className="col-lg-9 col-12 col-xl-10 ">
            <section className="custom-bottom-border second_border overflow-hidden w-100">
              <div className="row">
                <div className="col-12 description-section">
                  <div className="slider_parent pb-4 pb-lg-5">
                    <div
                      className="mb-4 pb-2 mt-md-3"
                      data-aos="fade-down"
                      data-aos-duration="1000"
                    >
                      <CommonHeading commonHeading=" What our students are saying" />
                    </div>
                    <Testiminals />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
    </>
  );
};

export default TestiminialsSection;
