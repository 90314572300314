import React from "react";

const AboutItemData = ({ obj }) => {
  return (
    <>
      <li
        data-aos="zoom-in"
        data-aos-duration="1000"
        className="para color-dark-gray mb-2"
      >
        {obj.title}
      </li>
    </>
  );
};

export default AboutItemData;
