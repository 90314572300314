import React from "react";
import CommonHeading from "./common/CommonHeading";
import CommonPointHeading from "./common/CommonPointHeading";
import leftAnimatedArrowImg from "../assets/img/svg/animated-left-arrow-icon.svg";
import ProgramItem from "./common/ProgramItem";
import { programData } from "./common/Helper";

const Program = () => {
  return (
    <>
      <section className="custom-bottom-border position-relative" id="program">
        {/* ANIMATED IMAGE LEFT-UP SIDE ARROW */}
        <img
          className="position-absolute custom-position-side-img3 d-none d-lg-block"
          src={leftAnimatedArrowImg}
          alt="leftAnimatedArrowImg"
        />

        <div className="container container_modified">
          <div className="row">
            <div className="col-12 description-section">
              <div className="row">
                <CommonPointHeading pointText="Program" />
                <div className="col-12 col-md-10 pt-4 pt-md-0">
                  <h5 className="d-flex flex-column mb-0 about-heading-text py-2 px-3 d-lg-none sidebar_text">
                    <span className="mb-0 para font-lg sidebar_text">
                      Program
                    </span>
                  </h5>

                  <div className="ps-md-4 ps-xl-5 py-4 py-sm-5 description-content custom-bottom-border2">
                    <div className="mb-4 pb-2 mt-md-3">
                      <CommonHeading commonHeading="What Makes Our Program Different?" />
                    </div>

                    <div className="row">
                      {programData.map((item, index) => (
                        <ProgramItem item={item} key={index} id={index} />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Program;
