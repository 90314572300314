import React from "react";
import CommonPointHeading from "../common/CommonPointHeading";
import motionsvg from "../../assets/img/svg/motion-svg-arrow-icon.svg";
import CommonHeading from "../common/CommonHeading";

import WhatYouLearnData from "./WhatYouLearnData";
import { whatYouLearnData } from "./Helper";

const WhatYouLearn = () => {
  return (
    <>
      {/* 04 */}
      <section
        className="custom-bottom-border position-relative whats_included"
        id="what-you-learn"
      >
        <div className="container container_modified">
          <div className="row">
            <div className="col-12 description-section ">
              <div className="row sidebar_text_parent what-you-learn-content">
                <CommonPointHeading pointNo="04" pointText="YOU'LL LEARN" />
                <div className="col-12 col-lg-10 pt-5 pt-md-0 ">
                  <h5 className="d-flex flex-column mb-0 about-heading-text py-2 px-3 d-lg-none">
                    <span className="mb-0 para font-lg sidebar_text">
                      YOU'LL LEARN
                    </span>
                  </h5>

                  <div className="ps-2 ps-md-4 ps-xl-5 py-4 py-sm-5 description-content custom-bottom-border2 custom_line what_learn_parent_01 ">
                    <div
                      className="mt-3 mb-2"
                      data-aos="fade-down"
                      data-aos-duration="1000"
                    >
                      <CommonHeading commonHeading="What you’ll learn" />
                    </div>
                    <p
                      className="para mb-5"
                      data-aos="zoom-in"
                      data-aos-delay="200"
                      data-aos-duration="1000"
                    >
                      No fluff. No spiraling. No forgetting everything you’ve
                      learned.
                    </p>
                    {whatYouLearnData &&
                      whatYouLearnData.map((item, index) => (
                        <WhatYouLearnData item={item} key={index} id={index} />
                      ))}
                    <div className="mb-xl-2 pb-xl-1"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ANIMATED IMAGE RIGHT SIDE ARROW */}
        <img
          className="position-absolute custom-position-side-img2 d-none d-md-block what-you-learn-icon"
          src={motionsvg}
          alt="svg "
        />
      </section>
    </>
  );
};

export default WhatYouLearn;
