import React, { useState } from "react";
import CommonPointHeading from "../common/CommonPointHeading";
import CommonHeading from "../common/CommonHeading";
import EnrollImg from "../../assets/img/png/enroll-img.png";
import EnrollImg2 from "../../assets/img/png/enroll-img-2.png";
import ilustration_img from "../../assets/img/png/3d_ilustration_img.png";
import motionsvg from "../../assets/img/svg/motion-svg-arrow-icon.svg";
import KlaviyoData from "../../views/KlaviyoData";

const EnrollNow = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    window.location.reload(true);
  };
  const handleShow = () => setShow(true);
  return (
    <>
      {/* 06 */}
      <section
        className="custom-bottom-border position-relative whats_included"
        id="what-you-learn"
      >
        <div className="container container_modified">
          <div className="row">
            <div className="col-12 description-section">
              <div className="row sidebar_text_parent">
                <CommonPointHeading pointNo="06" pointText="Enroll Now" />
                <div className="col-12 col-lg-10 pt-5 pt-md-0">
                  <h5 className="d-flex flex-column mb-0 about-heading-text enroll_text py-2 px-3 d-lg-none enroll_parent_01">
                    <span className="mb-0 para font-lg sidebar_text">
                      Enroll before 08/04/23 for $2k worth of bonuses
                    </span>
                  </h5>

                  <div className="ps-2 ps-md-4 ps-xl-5 py-4 pt-md-5 py-lg-5 description-content custom-bottom-border2 custom_line overflow-hidden">
                    <div className="my-3">
                      <CommonHeading commonHeading="Enroll before 08/04/23 for $2k worth of bonuses" />
                      <div className="row mt-3 pt-3">
                        <div
                          className="col-12 col-sm-10 col-md-6"
                          data-aos="zoom-in"
                          data-aos-delay="200"
                          data-aos-duration="1000"
                        >
                          <img
                            className="w-100"
                            src={EnrollImg}
                            alt="EnrollImg"
                          />
                          <h4 className="font-xl fw-normal color-lightgrey mt-3 pt-1 mb-0">
                            90-day access to material
                          </h4>
                          <p className="para">
                            Take extra time to revisit lessons and eliminate any
                            blind spots
                          </p>
                        </div>
                        <div
                          className="col-12 col-sm-10 col-md-6 mt-3 mt-sm-4 mt-md-0"
                          data-aos="zoom-in"
                          data-aos-delay="200"
                          data-aos-duration="1000"
                        >
                          <img
                            className="w-100"
                            src={EnrollImg2}
                            alt="EnrollImg"
                          />
                          <h4 className="font-xl fw-normal color-lightgrey mt-3 pt-1 mb-0">
                            $500 off our 6-month bootcamp
                          </h4>
                          <p className="para">
                            Become a full-stack software engineer in our 6-month
                            boot camp for $500 less
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="custom-bottom-border position-relative whats_included overflow-y-hidden01"
        id="what-you-learn"
      >
        <div className="container container_modified">
          <div className="row">
            <div className="col-12 description-section">
              <div className="row sidebar_text_parent enroll_now_sure_stuff_parent">
                <CommonPointHeading pointNo="06" pointText="Enroll Now" />
                <div className="col-12 col-lg-10">
                  <div className="ps-2 ps-md-4 ps-xl-5 py-4 py-sm-5 description-content custom-bottom-border2 custom_line">
                    {/* BREAKING STUFF */}
                    <div className="row align-items-center mt-3 pt-3 breaking-stuff-content">
                      <div
                        className="col-12 col-sm-10 col-md-6"
                        data-aos="zoom-in"
                        data-aos-delay="200"
                        data-aos-duration="1000"
                      >
                        <img
                          className="w-100"
                          src={ilustration_img}
                          alt="ilustration_img"
                        />
                      </div>
                      <div
                        className="co-12 col-md-6 mt-3 mt-sm-4 mt-md-0"
                        data-aos="zoom-in"
                        data-aos-delay="200"
                        data-aos-duration="1000"
                      >
                        <h4 className="font-2xl fw-normal color-lightgrey text-capitalize">
                          Sure, you could keep
                          <br className="d-none d-md-inline-block" />
                          writing, testing, and breaking stuff on your own
                          indefinitely
                        </h4>
                        <p className="para pb-lg-4 common-line-height">
                          Or you could master CSS/HTML in one month with expert
                          guidance and ongoing support
                        </p>
                        <button
                          onClick={handleShow}
                          className="text-nowrap d-inline-block common-btn hero-btn position-relative font-xs course_schedule_btn fw-bold"
                        >
                          <span className="d-flex align-items-center justify-content-center justify-content-md-start">
                            Schedule 15-min Discovery Call
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ANIMATED IMAGE RIGHT SIDE ARROW */}
        <img
          className="position-absolute d-none d-lg-block enroll-icon"
          src={motionsvg}
          alt="svg "
        />
      </section>
      <KlaviyoData handleClose={handleClose} show={show} />
    </>
  );
};

export default EnrollNow;
