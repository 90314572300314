import React from "react";

const InstructionsItem = ({ item, index }) => {
  return (
    <>
      <div key={index} id={index}>
        <p className="para mb-0 custom-lineheight">{item.instructionsPara}</p>
      </div>
    </>
  );
};

export default InstructionsItem;
