import React, { useEffect, useState } from "react";
import { Instagram, Telegram, Twitter } from "./Icons";

const FixedIcon = () => {
  const [issVisible, setIssVisible] = useState(false);
  const [pageYOffset, setPageYOffset] = useState(0);

  // TO FIND SCROLL Y POSITION
  useEffect(() => {
    window.addEventListener("scroll", function () {
      setPageYOffset(window.pageYOffset);
    });
  }, []);

  // THIS USEFFECT GIVE US POSITION OF SCROLL AT Y-AXIS IN EVERY PIXELS OF PAGE WHEN WE SCROLL AND
  // IF PAGE SCROLL VALUE GRATER THAN 300 THEN FIXED ICONS HIDE
  useEffect(() => {
    if (pageYOffset > 300) {
      setIssVisible(true);
    } else {
      setIssVisible(false);
    }
  }, [pageYOffset]);
  return (
    <div
      className={`position-fixed position_of_icon  ${
        issVisible ? "show-icons" : ""
      }`}
      data-aos="fade"
      data-aos-delay="500"
    >
      <div className="fixed_icon">
        <a href="https://twitter.com/" target="_blank" rel="noreferrer">
          <Twitter />
        </a>
      </div>
      <div className="fixed_icon  pt_custom">
        <a href="https://www.instagram.com/" target="_blank" rel="noreferrer">
          <Instagram />
        </a>
      </div>
      <div className="fixed_icon pt_custom">
        <a href="https://telegram.org/" target="_blank" rel="noreferrer">
          <Telegram />
        </a>
      </div>
    </div>
  );
};

export default FixedIcon;
