import React, { useState } from "react";
import logoWolfImg from "../assets/img/png/logo-wolf-image.png";
import KlaviyoData from "../views/KlaviyoData";

const Hero = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    window.location.reload(true);
  };
  const handleShow = () => setShow(true);
  return (
    <>
      <div className="overflow-hidden">
        <div className="container container_modified py-5 d-flex justify-content-center align-items-center hero-content">
          <div className="row justify-content-center align-items-center">
            <div className="col-12 text-center text-xl-start ms-xl-5">
              <h2
                className="text-uppercase main-heading mb-0 ms-xl-5 ps-xl-3"
                data-aos="fade-right"
                data-aos-delay="300"
                data-aos-duration="800"
              >
                <span className="ms-xl-5">Become a</span>
              </h2>
            </div>

            <div className="col-12 ms-lg-5 ps-lg-3 my-2 my-lg-0">
              <div className="row align-items-center">
                <div className="col-12 col-lg-6 col-xxl-5 mb-2 mb-lg-0">
                  <h1
                    className="text-uppercase main-heading mb-0 d-flex align-items-center justify-content-center justify-content-xl-start"
                    data-aos="fade-up"
                    data-aos-delay="300"
                    data-aos-duration="800"
                  >
                    S
                    <img
                      className="d-flex align-items-center wolf_img"
                      src={logoWolfImg}
                      alt="logoWolfImg"
                      id="rotate"
                    />
                    ftware
                  </h1>
                </div>
                <div className="col-12 text-center d-lg-none">
                  <h2
                    className="text-uppercase main-heading mb-lg-5 pb-3"
                    data-aos="fade-left"
                    data-aos-delay="300"
                    data-aos-duration="800"
                  >
                    Engineer
                  </h2>
                </div>
                <div className="col-12 col-lg-6 col-xxl-5 ms-xxl-5">
                  <p
                    className="para text-center text-xl-start mb-0 pe-xl-5 pe-xxl-0"
                    data-aos="fade-down"
                    data-aos-delay="300"
                    data-aos-duration="800"
                  >
                    Learn the highly-paid software development skills top
                    companies are hungry for and build an impressive portfolio
                    that gets you hired—even if you don’t have a math or science
                    background.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-12 text-center d-none d-lg-block">
              <h2
                className="text-uppercase main-heading mb-lg-5 pb-3"
                data-aos="fade-left"
                data-aos-delay="300"
                data-aos-duration="800"
              >
                Engineer
              </h2>
            </div>

            <div
              className="col-12 text-center"
              data-aos="fade-left"
              data-aos-delay="300"
              data-aos-duration="800"
              // data-aos-duration="2000"
            >
              <button
                onClick={handleShow}
                className=" d-inline-block text-uppercase common-btn hero-btn position-relative my-3"
              >
                <span className="d-flex align-items-center">Learn More</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <KlaviyoData handleClose={handleClose} show={show} />
    </>
  );
};

export default Hero;
