import React from "react";

const CommonPointHeading = ({ pointNo, pointText }) => {
  return (
    <>
      <div className="col-12 col-lg-2 pt-4 py-lg-5 mt-lg-3 d-none d-lg-block ">
        <h5 className="d-flex flex-column mb-0">
          {pointNo}
          <span className="mt-1 mt-lg-3 mb-0 para font-lg sidebar_text">
            {pointText}
          </span>
        </h5>
      </div>
    </>
  );
};

export default CommonPointHeading;
