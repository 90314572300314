import React, { useRef } from "react";
import Slider from "react-slick";
import { sliderData } from "../common/Helper";
import { SliderLeftArrow, SliderRightArrow } from "../common/Icons";
import TestimonialCard from "../TestimonialCard";

const StudentsSlider = () => {
  const slider = useRef();
  var settings = {
    dots: false,
    autoplay: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1450,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          arrows: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          arrows: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },

      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
    ],
  };
  return (
    <>
      <section className="students_slider overflow-hidden">
        <div className="container py-5">
          <div className="text-center">
            <h3 className="sub-heading">
              What Our <span className="color-orange">Students</span> Are Saying
            </h3>
          </div>
          <Slider
            className="position-relative px-md-3 px-xxl-5 mt-5 pt-sm-3 mb-sm-3 mb-xl-0 pb-5 pb-xl-0"
            {...settings}
            ref={slider}
          >
            {sliderData.map((obj, index) => (
              <TestimonialCard obj={obj} key={index} />
            ))}
          </Slider>

          <div
            className="text-center mt-3 pt-5 pt-xl-3"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <a
              href="https://app.squarespacescheduling.com/schedule.php?owner=27771236"
              className="modal-btn common-btn text-decoration-none text-uppercase font-sm"
            >
              Book a call with my team
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default StudentsSlider;
function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="slider-right-arrow  slider_icons_hover  student-slider-right-arrow d-flex justify-content-center align-items-center position-absolute ms-3 ms-sm-0"
      onClick={onClick}
    >
      <SliderRightArrow />
    </div>
  );
}
function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="slider-left-arrow  slider_icons_hover student-slider-left-arrow d-flex justify-content-center align-items-center position-absolute ms-3 ms-sm-0"
      onClick={onClick}
    >
      <SliderLeftArrow />
    </div>
  );
}
