// FIXED ICONS SVGS
export const Twitter = () => {
  return (
    <svg
      width="19"
      height="16"
      viewBox="0 0 19 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5807 2.60597C17.9202 2.89863 17.2107 3.09637 16.4648 3.18575C17.2345 2.72524 17.8102 2.00046 18.0847 1.14665C17.3617 1.57613 16.5703 1.87844 15.7451 2.04043C15.1901 1.4479 14.4551 1.05515 13.654 0.923179C12.853 0.791205 12.0308 0.927385 11.3151 1.31058C10.5994 1.69377 10.0302 2.30253 9.69592 3.04235C9.36164 3.78217 9.28096 4.61165 9.46642 5.40202C8.00131 5.32846 6.56804 4.94765 5.25963 4.28432C3.95122 3.62098 2.7969 2.68994 1.8716 1.55162C1.55522 2.09738 1.3733 2.73015 1.3733 3.40405C1.37295 4.01072 1.52234 4.60809 1.80823 5.14316C2.09412 5.67824 2.50766 6.13448 3.01217 6.4714C2.42708 6.45278 1.85489 6.29469 1.34324 6.01027V6.05773C1.34318 6.9086 1.63751 7.73328 2.17627 8.39185C2.71503 9.05042 3.46505 9.50231 4.29907 9.67084C3.75629 9.81773 3.18724 9.83937 2.63488 9.73412C2.87019 10.4662 3.32855 11.1065 3.9458 11.5651C4.56304 12.0238 5.30827 12.278 6.07715 12.2921C4.77193 13.3167 3.15999 13.8725 1.50064 13.8701C1.20671 13.8701 0.913019 13.853 0.621094 13.8186C2.30543 14.9016 4.26611 15.4764 6.26856 15.4741C13.0471 15.4741 16.7528 9.85988 16.7528 4.99072C16.7528 4.83253 16.7488 4.67275 16.7417 4.51456C17.4625 3.9933 18.0847 3.34781 18.5791 2.60834L18.5807 2.60597Z"
        fill="url(#paint0_linear_651_1429)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_651_1429"
          x1="0.621094"
          y1="1.30356"
          x2="6.8095"
          y2="12.7741"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2801" />
          <stop offset="1" stopColor="#FF6B0A" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export const Instagram = () => {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.36934 4.96915C7.00864 4.96915 5.8982 6.07958 5.8982 7.44028C5.8982 8.80099 7.00864 9.91142 8.36934 9.91142C9.73004 9.91142 10.8405 8.80099 10.8405 7.44028C10.8405 6.07958 9.73004 4.96915 8.36934 4.96915ZM15.7809 7.44028C15.7809 6.41698 15.7902 5.40294 15.7327 4.38149C15.6752 3.19505 15.4046 2.14208 14.537 1.27449C13.6675 0.405051 12.6164 0.136248 11.43 0.0787799C10.4067 0.0213116 9.39265 0.0305807 8.37119 0.0305807C7.34789 0.0305807 6.33385 0.0213116 5.3124 0.0787799C4.12595 0.136248 3.07299 0.406905 2.2054 1.27449C1.33596 2.14393 1.06716 3.19505 1.00969 4.38149C0.95222 5.40479 0.961489 6.41883 0.961489 7.44028C0.961489 8.46174 0.95222 9.47763 1.00969 10.4991C1.06716 11.6855 1.33781 12.7385 2.2054 13.6061C3.07484 14.4755 4.12595 14.7443 5.3124 14.8018C6.3357 14.8593 7.34974 14.85 8.37119 14.85C9.3945 14.85 10.4085 14.8593 11.43 14.8018C12.6164 14.7443 13.6694 14.4737 14.537 13.6061C15.4064 12.7366 15.6752 11.6855 15.7327 10.4991C15.792 9.47763 15.7809 8.46359 15.7809 7.44028ZM8.36934 11.2425C6.26526 11.2425 4.56716 9.54437 4.56716 7.44028C4.56716 5.3362 6.26526 3.63811 8.36934 3.63811C10.4734 3.63811 12.1715 5.3362 12.1715 7.44028C12.1715 9.54437 10.4734 11.2425 8.36934 11.2425ZM12.3272 4.37036C11.836 4.37036 11.4393 3.97365 11.4393 3.48239C11.4393 2.99113 11.836 2.59441 12.3272 2.59441C12.8185 2.59441 13.2152 2.99113 13.2152 3.48239C13.2154 3.59904 13.1925 3.71457 13.1479 3.82237C13.1033 3.93017 13.0379 4.02812 12.9555 4.11061C12.873 4.19309 12.775 4.2585 12.6672 4.30307C12.5594 4.34764 12.4439 4.37051 12.3272 4.37036Z"
        fill="url(#paint0_linear_651_1435)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_651_1435"
          x1="0.960937"
          y1="0.465927"
          x2="7.88698"
          y2="10.9024"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2801" />
          <stop offset="1" stopColor="#FF6B0A" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export const Telegram = () => {
  return (
    <svg
      width="17"
      height="15"
      viewBox="0 0 17 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6256 1.85375L14.1192 13.6737C13.93 14.5078 13.437 14.7154 12.7364 14.3227L8.91723 11.5084L7.07469 13.281C6.87062 13.485 6.70037 13.6553 6.30706 13.6553L6.58172 9.76614L13.6594 3.37062C13.9673 3.09655 13.5924 2.94409 13.1813 3.21875L4.4313 8.72859L0.664341 7.54927C-0.154898 7.29359 -0.169728 6.73003 0.835188 6.33672L15.569 0.66019C16.2512 0.404511 16.848 0.812054 16.6256 1.85434V1.85375Z"
        fill="url(#paint0_linear_651_1441)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_651_1441"
          x1="0.0644531"
          y1="0.995718"
          x2="6.06788"
          y2="11.7824"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2801" />
          <stop offset="1" stopColor="#FF6B0A" />
        </linearGradient>
      </defs>
    </svg>
  );
};
// BACK TO TOP ICON
export const BackToTopIcon = () => {
  return (
    <svg
      width="20"
      height="15"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12 8L7 3L2 8L0 7L7 0L14 7L12 8Z" fill="white" />
    </svg>
  );
};
export const SliderLeftArrow = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="22" cy="22" r="22" fill="url(#paint0_linear_651_1090)" />
      <path
        d="M26.7992 15.9996L20.7992 21.9996L26.7992 27.9996L25.5992 30.3996L17.1992 21.9996L25.5992 13.5996L26.7992 15.9996Z"
        fill="#F4F4F4"
      />
      <defs>
        <linearGradient
          id="paint0_linear_651_1090"
          x1="-1.14444e-07"
          y1="1.29412"
          x2="20.5626"
          y2="32.2782"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2801" />
          <stop offset="1" stopColor="#FF6B0A" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export const SliderRightArrow = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        r="22"
        transform="matrix(-1 0 0 1 22 22)"
        fill="url(#paint0_linear_651_1095)"
      />
      <path
        d="M17.2008 15.9996L23.2008 21.9996L17.2008 27.9996L18.4008 30.3996L26.8008 21.9996L18.4008 13.5996L17.2008 15.9996Z"
        fill="#F4F4F4"
      />
      <defs>
        <linearGradient
          id="paint0_linear_651_1095"
          x1="-1.14444e-07"
          y1="1.29412"
          x2="20.5626"
          y2="32.2782"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2801" />
          <stop offset="1" stopColor="#FF6B0A" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export const FooterRoundedText = () => {
  return (
    <svg
      width="165"
      height="165"
      viewBox="0 0 165 165"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M140.796 99.5268L128.904 95.1803L128.376 96.6243L140.268 100.971L140.796 99.5268ZM138.56 105.643L134.823 98.48L126.576 101.551L125.924 103.335L134.218 100.242L137.908 107.426L138.56 105.643Z"
        fill="url(#paint0_linear_651_1183)"
      />
      <path
        d="M129.337 105.724C128.625 105.292 127.889 105.064 127.128 105.04C126.371 105.032 125.664 105.21 125.007 105.573C124.35 105.936 123.811 106.463 123.393 107.153C122.967 107.855 122.749 108.575 122.737 109.316C122.719 110.066 122.891 110.77 123.254 111.427C123.621 112.101 124.16 112.654 124.872 113.085C125.594 113.523 126.333 113.746 127.09 113.754C127.84 113.772 128.544 113.599 129.202 113.236C129.853 112.884 130.391 112.357 130.816 111.656C131.235 110.965 131.453 110.244 131.471 109.494C131.489 108.743 131.32 108.034 130.964 107.366C130.601 106.709 130.058 106.161 129.337 105.724ZM128.577 106.976C129.072 107.277 129.447 107.645 129.704 108.083C129.964 108.537 130.093 109.01 130.091 109.503C130.082 110.005 129.937 110.488 129.656 110.952C129.375 111.416 129.016 111.763 128.581 111.992C128.139 112.232 127.66 112.337 127.143 112.305C126.63 112.29 126.127 112.133 125.632 111.833C125.137 111.533 124.764 111.158 124.514 110.711C124.258 110.273 124.129 109.8 124.127 109.291C124.129 108.799 124.271 108.321 124.552 107.857C124.834 107.393 125.195 107.041 125.637 106.801C126.083 106.578 126.562 106.474 127.075 106.489C127.581 106.514 128.082 106.676 128.577 106.976Z"
        fill="url(#paint1_linear_651_1183)"
      />
      <path
        d="M126.846 125.932L116.424 116.423L115.449 117.492L125.871 127.001L126.846 125.932ZM124.191 114.082C123.532 113.481 122.836 113.099 122.103 112.936C121.37 112.773 120.663 112.806 119.982 113.033C119.301 113.261 118.709 113.651 118.205 114.203C117.733 114.72 117.452 115.287 117.361 115.906C117.27 116.525 117.367 117.16 117.653 117.813C117.931 118.475 118.399 119.106 119.058 119.708C119.726 120.317 120.398 120.726 121.073 120.934C121.75 121.159 122.391 121.198 122.999 121.051C123.607 120.903 124.147 120.571 124.618 120.055C125.122 119.503 125.456 118.877 125.62 118.178C125.785 117.479 125.752 116.772 125.523 116.057C125.303 115.35 124.859 114.692 124.191 114.082ZM123.203 115.165C123.676 115.595 123.991 116.055 124.15 116.543C124.301 117.039 124.314 117.525 124.191 118C124.068 118.475 123.835 118.9 123.494 119.274C123.21 119.586 122.845 119.816 122.399 119.964C121.953 120.113 121.476 120.135 120.968 120.03C120.46 119.925 119.969 119.657 119.497 119.227C119.025 118.796 118.714 118.332 118.563 117.835C118.412 117.339 118.39 116.862 118.497 116.404C118.605 115.947 118.8 115.563 119.085 115.251C119.426 114.877 119.828 114.607 120.29 114.44C120.751 114.274 121.237 114.243 121.745 114.348C122.245 114.462 122.731 114.734 123.203 115.165Z"
        fill="url(#paint2_linear_651_1183)"
      />
      <path
        d="M111.194 120.663C110.48 121.199 109.968 121.802 109.658 122.473C109.345 123.16 109.224 123.914 109.297 124.734L110.683 124.621C110.62 124.035 110.686 123.51 110.881 123.047C111.083 122.594 111.411 122.197 111.864 121.857C112.317 121.516 112.775 121.323 113.238 121.277C113.701 121.231 114.152 121.329 114.591 121.573C115.03 121.816 115.445 122.198 115.836 122.719C116.211 123.237 116.446 123.731 116.543 124.201C116.637 124.689 116.599 125.14 116.428 125.554C116.254 125.986 115.946 126.369 115.502 126.702C115.126 126.984 114.737 127.148 114.334 127.194C113.932 127.241 113.544 127.177 113.173 127.003C112.792 126.837 112.453 126.557 112.156 126.161C112.105 126.094 112.055 125.996 112.004 125.868C111.96 125.75 111.935 125.656 111.928 125.586L111.964 126.599L116.737 123.015L115.999 122.031L110.315 126.3C110.329 126.319 110.362 126.362 110.412 126.43C110.461 126.514 110.51 126.59 110.561 126.658C111.09 127.362 111.671 127.875 112.306 128.198C112.94 128.521 113.595 128.64 114.27 128.555C114.943 128.487 115.607 128.207 116.263 127.714C116.918 127.222 117.388 126.643 117.673 125.977C117.947 125.318 118.029 124.624 117.917 123.893C117.796 123.17 117.475 122.461 116.954 121.767C116.44 121.082 115.857 120.577 115.206 120.252C114.545 119.934 113.866 119.803 113.169 119.859C112.469 119.932 111.811 120.2 111.194 120.663Z"
        fill="url(#paint3_linear_651_1183)"
      />
      <path
        d="M107.568 137.882L103.192 126.001L101.749 126.533L106.126 138.414L107.568 137.882ZM99.6276 127.314C98.4735 127.74 97.5412 128.366 96.8307 129.193C96.1202 130.02 95.6729 130.969 95.4888 132.039C95.3089 133.12 95.4378 134.255 95.8754 135.443C96.3131 136.631 96.9491 137.573 97.7834 138.268C98.6218 138.974 99.5797 139.411 100.657 139.58C101.734 139.749 102.85 139.62 104.004 139.195L106.771 138.176L106.239 136.733L103.473 137.752C102.805 137.998 102.154 138.116 101.521 138.105C100.891 138.106 100.306 137.981 99.7638 137.731C99.226 137.492 98.7475 137.135 98.3283 136.66C97.9091 136.185 97.5724 135.602 97.3181 134.912C97.0639 134.221 96.9421 133.559 96.9529 132.926C96.9636 132.292 97.0941 131.704 97.3442 131.162C97.5985 130.632 97.9633 130.157 98.4385 129.738C98.9179 129.33 99.4914 129.003 100.159 128.757L102.926 127.738L102.394 126.295L99.6276 127.314Z"
        fill="url(#paint4_linear_651_1183)"
      />
      <path
        d="M89.5296 129.825C88.6478 129.962 87.9119 130.252 87.3219 130.698C86.7219 131.157 86.2608 131.765 85.9388 132.522L87.2141 133.075C87.4346 132.529 87.7399 132.097 88.1302 131.781C88.5223 131.476 88.9983 131.28 89.5584 131.193C90.1185 131.107 90.6136 131.152 91.0437 131.329C91.4738 131.507 91.8253 131.806 92.0979 132.228C92.3706 132.649 92.5568 133.182 92.6564 133.825C92.7422 134.459 92.7171 135.006 92.5809 135.466C92.4347 135.94 92.1884 136.32 91.8422 136.605C91.4858 136.904 91.0336 137.096 90.4854 137.181C90.0207 137.253 89.5998 137.215 89.2229 137.066C88.846 136.917 88.5345 136.678 88.2885 136.35C88.0306 136.024 87.8638 135.617 87.7882 135.128C87.7752 135.045 87.7765 134.935 87.792 134.798C87.8093 134.673 87.8312 134.578 87.8577 134.513L87.413 135.424L93.3116 134.511L93.1234 133.295L86.0987 134.383C86.1024 134.407 86.1107 134.46 86.1236 134.544C86.1265 134.641 86.1344 134.731 86.1473 134.815C86.282 135.685 86.5531 136.411 86.9607 136.995C87.3683 137.578 87.8899 137.992 88.5254 138.235C89.1509 138.492 89.8688 138.558 90.6791 138.433C91.4894 138.307 92.1767 138.018 92.741 137.564C93.2934 137.113 93.6926 136.538 93.9386 135.841C94.1726 135.146 94.2232 134.37 94.0904 133.512C93.9594 132.666 93.6832 131.946 93.2619 131.352C92.8286 130.76 92.2914 130.325 91.6503 130.046C90.9992 129.781 90.2923 129.707 89.5296 129.825Z"
        fill="url(#paint5_linear_651_1183)"
      />
      <path
        d="M79.0004 135.561L79.1391 130.499L77.6926 130.459L77.549 135.702C77.5216 136.703 77.7591 137.5 78.2616 138.092C78.7516 138.697 79.4366 139.011 80.3166 139.035C80.859 139.05 81.3444 138.943 81.7729 138.713C82.1894 138.483 82.5431 138.119 82.8342 137.62L82.7986 138.922L84.245 138.962L84.4729 130.645L83.0264 130.605L82.8877 135.668C82.8765 136.078 82.7822 136.437 82.6049 136.746C82.4155 137.054 82.1618 137.288 81.8438 137.449C81.5134 137.62 81.1372 137.701 80.7153 137.689C80.1368 137.673 79.7016 137.486 79.4098 137.129C79.118 136.771 78.9816 136.248 79.0004 135.561Z"
        fill="url(#paint6_linear_651_1183)"
      />
      <path
        d="M70.0802 129.761C70.1508 129.505 70.1204 129.252 69.989 129.004C69.8428 128.763 69.6418 128.607 69.3861 128.537C69.1188 128.463 68.8664 128.493 68.6291 128.628C68.3886 128.774 68.233 128.975 68.1624 129.231C68.0885 129.498 68.1189 129.751 68.2535 129.988C68.3881 130.225 68.5891 130.381 68.8564 130.455C69.1122 130.525 69.3645 130.495 69.6134 130.363C69.8508 130.229 70.0064 130.028 70.0802 129.761Z"
        fill="url(#paint7_linear_651_1183)"
      />
      <path
        d="M55.3738 137.142L61.6502 126.146L60.3149 125.384L54.0386 136.38L55.3738 137.142ZM49.7187 133.914L57.4058 131.429L55.7594 122.783L54.11 121.842L55.7699 130.537L48.0693 132.973L49.7187 133.914Z"
        fill="url(#paint8_linear_651_1183)"
      />
      <path
        d="M51.2285 124.832C51.7731 124.203 52.1213 123.516 52.2729 122.77C52.4074 122.025 52.351 121.298 52.1035 120.589C51.8559 119.881 51.4268 119.262 50.8161 118.733C50.1963 118.196 49.5224 117.86 48.7946 117.724C48.0577 117.58 47.3348 117.632 46.6259 117.88C45.9001 118.128 45.2648 118.567 44.7201 119.196C44.1675 119.834 43.824 120.526 43.6894 121.271C43.5457 122.007 43.5976 122.73 43.8451 123.439C44.0835 124.14 44.5126 124.759 45.1325 125.296C45.7432 125.825 46.417 126.161 47.154 126.305C47.8909 126.448 48.6184 126.4 49.3363 126.161C50.0452 125.913 50.6759 125.47 51.2285 124.832ZM50.121 123.873C49.742 124.311 49.3154 124.619 48.841 124.799C48.3496 124.979 47.8615 125.027 47.3767 124.942C46.8828 124.85 46.4307 124.626 46.0206 124.27C45.6104 123.915 45.3286 123.504 45.1754 123.036C45.013 122.56 44.9906 122.07 45.1083 121.566C45.2089 121.063 45.4487 120.593 45.8276 120.155C46.2065 119.718 46.6377 119.413 47.1212 119.242C47.5956 119.062 48.0837 119.014 48.5855 119.098C49.0703 119.183 49.5178 119.403 49.928 119.758C50.3382 120.113 50.6245 120.529 50.7869 121.004C50.9323 121.481 50.9546 121.971 50.854 122.474C50.7442 122.969 50.4999 123.436 50.121 123.873Z"
        fill="url(#paint9_linear_651_1183)"
      />
      <path
        d="M31.7564 119.049L42.8498 110.333L41.9558 109.195L30.8624 117.911L31.7564 119.049ZM43.8811 118.379C44.5827 117.827 45.0741 117.203 45.3551 116.507C45.6362 115.811 45.7205 115.108 45.6081 114.399C45.4956 113.69 45.2085 113.042 44.7466 112.454C44.3145 111.904 43.8007 111.533 43.2054 111.341C42.6101 111.15 41.9671 111.141 41.2762 111.316C40.578 111.481 39.878 111.84 39.1764 112.391C38.4653 112.95 37.9516 113.545 37.6353 114.177C37.3021 114.807 37.1582 115.434 37.2036 116.057C37.249 116.681 37.4877 117.268 37.9198 117.818C38.3817 118.406 38.9439 118.838 39.6062 119.115C40.2686 119.392 40.9713 119.476 41.7142 119.368C42.4477 119.267 43.17 118.937 43.8811 118.379ZM42.9759 117.227C42.4734 117.621 41.9685 117.857 41.4612 117.934C40.9465 118.001 40.465 117.934 40.0166 117.735C39.5683 117.535 39.1877 117.236 38.8748 116.838C38.614 116.506 38.4471 116.108 38.3739 115.644C38.3008 115.18 38.3576 114.706 38.5446 114.222C38.7315 113.737 39.0762 113.298 39.5787 112.903C40.0812 112.508 40.5899 112.277 41.1046 112.21C41.6193 112.143 42.0934 112.2 42.5268 112.381C42.9603 112.562 43.3074 112.818 43.5681 113.15C43.881 113.548 44.0815 113.989 44.1696 114.471C44.2576 114.954 44.2082 115.438 44.0213 115.922C43.8269 116.397 43.4785 116.832 42.9759 117.227Z"
        fill="url(#paint10_linear_651_1183)"
      />
      <path
        d="M39.5538 104.507C39.1414 103.716 38.6296 103.112 38.0185 102.696C37.3912 102.275 36.6671 102.034 35.8463 101.972L35.7321 103.358C36.3205 103.391 36.8274 103.542 37.2526 103.809C37.6671 104.083 38.0053 104.471 38.2673 104.973C38.5292 105.476 38.6452 105.959 38.6153 106.424C38.5854 106.888 38.4146 107.317 38.1031 107.711C37.7915 108.104 37.347 108.452 36.7696 108.753C36.1973 109.037 35.6713 109.189 35.1916 109.208C34.6956 109.222 34.2569 109.11 33.8754 108.874C33.4776 108.633 33.1505 108.266 32.8941 107.774C32.6767 107.357 32.5781 106.946 32.5983 106.541C32.6184 106.137 32.7443 105.765 32.9759 105.427C33.2019 105.078 33.5341 104.789 33.9725 104.56C34.0474 104.521 34.152 104.487 34.2863 104.458C34.41 104.434 34.5069 104.425 34.5771 104.429L33.5717 104.3L36.3307 109.593L37.4214 109.025L34.1357 102.721C34.1143 102.732 34.0662 102.757 33.9913 102.796C33.9002 102.83 33.8172 102.867 33.7424 102.906C32.9618 103.313 32.3606 103.803 31.9388 104.376C31.517 104.95 31.293 105.576 31.2667 106.256C31.2241 106.931 31.3923 107.632 31.7714 108.359C32.1504 109.087 32.6452 109.645 33.2558 110.033C33.8608 110.411 34.5329 110.605 35.2719 110.614C36.0054 110.613 36.7571 110.411 37.527 110.01C38.2862 109.614 38.8792 109.121 39.3062 108.532C39.7275 107.931 39.9674 107.283 40.0257 106.586C40.0678 105.884 39.9105 105.191 39.5538 104.507Z"
        fill="url(#paint11_linear_651_1183)"
      />
      <path
        d="M23.1719 98.1355L35.6121 95.7802L35.3261 94.2696L22.8859 96.6249L23.1719 98.1355ZM34.9055 92.0482C34.6767 90.8397 34.2131 89.8168 33.5146 88.9795C32.8161 88.1422 31.9543 87.5445 30.9292 87.1863C29.8923 86.8303 28.7518 86.7701 27.5078 87.0057C26.2638 87.2412 25.2302 87.713 24.407 88.4211C23.5719 89.1315 22.9824 90.004 22.6383 91.0387C22.2942 92.0733 22.2365 93.1949 22.4653 94.4034L23.0137 97.3002L24.5243 97.0142L23.9759 94.1174C23.8436 93.4184 23.835 92.7573 23.9502 92.1341C24.0535 91.5132 24.2733 90.9561 24.6095 90.463C24.9338 89.972 25.3649 89.559 25.9028 89.224C26.4408 88.889 27.0711 88.6531 27.7938 88.5163C28.5165 88.3794 29.1895 88.3686 29.8126 88.4838C30.4358 88.599 30.994 88.8247 31.4871 89.1609C31.9685 89.4993 32.3766 89.9374 32.7117 90.4754C33.0348 91.0155 33.2626 91.6351 33.3949 92.3342L33.9434 95.231L35.454 94.945L34.9055 92.0482Z"
        fill="url(#paint12_linear_651_1183)"
      />
      <path
        d="M34.0972 81.6585C34.1115 80.7663 33.949 79.9919 33.6097 79.3353C33.2586 78.6664 32.7369 78.1093 32.0445 77.6641L31.2842 78.8278C31.7858 79.1373 32.1597 79.5111 32.406 79.9492C32.6402 80.3871 32.7528 80.8893 32.7437 81.456C32.7347 82.0227 32.6064 82.503 32.3589 82.8971C32.1114 83.2911 31.757 83.5869 31.2955 83.7846C30.8341 83.9822 30.2778 84.0758 29.6268 84.0654C28.988 84.0432 28.4532 83.9261 28.0224 83.7142C27.5798 83.49 27.247 83.1832 27.0241 82.7937C26.7893 82.392 26.6763 81.9139 26.6852 81.3592C26.6927 80.889 26.8017 80.4807 27.0123 80.1344C27.2228 79.788 27.5105 79.5212 27.8753 79.3341C28.2403 79.1349 28.6699 79.0393 29.1642 79.0472C29.2486 79.0485 29.3569 79.0683 29.4889 79.1066C29.6089 79.1447 29.6987 79.1823 29.7585 79.2195L28.9358 78.6275L28.8404 84.5956L30.0702 84.6152L30.1838 77.5077C30.1597 77.5073 30.1054 77.5065 30.021 77.5051C29.9247 77.4915 29.8344 77.4841 29.75 77.4827C28.8699 77.4687 28.1078 77.6133 27.4638 77.9165C26.8198 78.2198 26.3242 78.6641 25.9772 79.2495C25.6183 79.8227 25.4323 80.5192 25.4192 81.339C25.4061 82.1589 25.5754 82.8852 25.9271 83.5179C26.279 84.1386 26.7777 84.6289 27.4232 84.989C28.0688 85.337 28.8257 85.5179 29.6938 85.5318C30.5498 85.5454 31.306 85.3947 31.9622 85.0796C32.6187 84.7524 33.1385 84.2964 33.5217 83.7116C33.8931 83.1145 34.0849 82.4302 34.0972 81.6585Z"
        fill="url(#paint13_linear_651_1183)"
      />
      <path
        d="M30.1894 70.3198L35.1567 71.3068L35.4387 69.8875L30.294 68.8653C29.3123 68.6702 28.4871 68.7706 27.8183 69.1663C27.1401 69.5479 26.7152 70.1704 26.5437 71.0338C26.4379 71.566 26.4622 72.0626 26.6165 72.5235C26.7731 72.9727 27.0728 73.3826 27.5155 73.7533L26.2382 73.4995L25.9562 74.9188L34.1169 76.5403L34.3989 75.121L29.4315 74.134C29.0294 74.0541 28.691 73.9008 28.4164 73.6741C28.1441 73.4356 27.9558 73.1462 27.8513 72.8058C27.7373 72.4512 27.7215 72.0669 27.8037 71.653C27.9165 71.0853 28.1738 70.6877 28.5755 70.4601C28.9773 70.2326 29.5152 70.1858 30.1894 70.3198Z"
        fill="url(#paint14_linear_651_1183)"
      />
      <path
        d="M37.3579 62.4571C37.5981 62.5697 37.8519 62.5823 38.1194 62.4947C38.3812 62.391 38.5684 62.2191 38.681 61.979C38.7988 61.7279 38.8114 61.474 38.7187 61.2174C38.615 60.9557 38.4431 60.7685 38.203 60.6558C37.9519 60.538 37.698 60.5255 37.4414 60.6182C37.1848 60.7109 36.9976 60.8828 36.8798 61.1339C36.7671 61.3741 36.7546 61.6279 36.8422 61.8954C36.9349 62.1521 37.1068 62.3393 37.3579 62.4571Z"
        fill="url(#paint15_linear_651_1183)"
      />
      <path
        d="M32.3819 46.9122L42.1951 54.9127L43.1666 53.7211L33.3534 45.7206L32.3819 46.9122ZM36.4965 41.8654L37.6825 49.8568L46.4811 49.6556L47.6812 48.1837L38.8317 48.39L37.6966 40.3934L36.4965 41.8654Z"
        fill="url(#paint16_linear_651_1183)"
      />
      <path
        d="M45.2795 44.7496C45.8101 45.3905 46.4308 45.8472 47.1417 46.1196C47.8542 46.3751 48.5804 46.4392 49.3203 46.3119C50.0603 46.1845 50.7414 45.8633 51.3637 45.348C51.9953 44.8251 52.438 44.2159 52.6919 43.5204C52.9551 42.8173 53.023 42.0957 52.8957 41.3557C52.77 40.5988 52.4418 39.8999 51.9112 39.259C51.3729 38.6089 50.7475 38.156 50.035 37.9006C49.3319 37.6374 48.6103 37.5695 47.8704 37.6968C47.1397 37.8164 46.4586 38.1377 45.827 38.6606C45.2047 39.1758 44.7619 39.785 44.4988 40.4882C44.2356 41.1914 44.163 41.9168 44.2811 42.6644C44.4084 43.4044 44.7412 44.0994 45.2795 44.7496ZM46.408 43.8153C46.0389 43.3695 45.8049 42.8978 45.7062 42.4004C45.609 41.8859 45.6422 41.3966 45.8058 40.9324C45.9787 40.4605 46.2741 40.0516 46.6921 39.7055C47.1101 39.3595 47.5623 39.1494 48.0489 39.0753C48.5448 38.9935 49.0317 39.0522 49.5097 39.2514C49.9892 39.4335 50.4136 39.7475 50.7827 40.1934C51.1518 40.6392 51.3811 41.1147 51.4706 41.6198C51.5693 42.1173 51.5361 42.6066 51.3709 43.0878C51.2073 43.552 50.9165 43.9571 50.4986 44.3031C50.0806 44.6492 49.6237 44.8631 49.1278 44.9449C48.6336 45.0097 48.1467 44.951 47.6671 44.7688C47.1968 44.579 46.7771 44.2611 46.408 43.8153Z"
        fill="url(#paint17_linear_651_1183)"
      />
      <path
        d="M54.2592 26.444L61.0066 38.8341L62.2774 38.1421L55.53 25.752L54.2592 26.444ZM52.9014 38.5111C53.3282 39.2947 53.8614 39.8831 54.5011 40.2762C55.1408 40.6692 55.8196 40.8694 56.5375 40.8766C57.2554 40.8838 57.9427 40.7086 58.5993 40.3511C59.2135 40.0166 59.6646 39.5718 59.9527 39.0167C60.2409 38.4616 60.3563 37.829 60.299 37.1187C60.2523 36.4027 60.0156 35.6528 59.5889 34.8692C59.1563 34.075 58.6549 33.4693 58.0845 33.0522C57.5189 32.6188 56.9248 32.3725 56.3022 32.3134C55.6796 32.2543 55.0612 32.3919 54.447 32.7264C53.7904 33.084 53.2704 33.5663 52.887 34.1733C52.5036 34.7802 52.3034 35.4591 52.2865 36.2097C52.2639 36.9497 52.4689 37.7169 52.9014 38.5111ZM54.1881 37.8104C53.8824 37.2491 53.7341 36.712 53.7431 36.1991C53.7627 35.6804 53.9083 35.2167 54.18 34.8078C54.4516 34.399 54.8098 34.0735 55.2546 33.8313C55.6253 33.6294 56.0454 33.5311 56.515 33.5362C56.9846 33.5413 57.4426 33.6764 57.889 33.9413C58.3354 34.2063 58.7114 34.6194 59.017 35.1806C59.3227 35.7419 59.4657 36.2819 59.4461 36.8006C59.4265 37.3193 59.2915 37.7772 59.041 38.1745C58.7905 38.5718 58.48 38.8714 58.1093 39.0732C57.6645 39.3154 57.1967 39.4398 56.7059 39.4462C56.2151 39.4526 55.7466 39.3233 55.3002 39.0584C54.8644 38.7876 54.4937 38.3717 54.1881 37.8104Z"
        fill="url(#paint18_linear_651_1183)"
      />
      <path
        d="M67.3124 36.5203C68.1615 36.2459 68.842 35.8421 69.3541 35.3091C69.8738 34.7609 70.2327 34.0873 70.4307 33.2883L69.0839 32.9443C68.9528 33.5189 68.7197 33.9935 68.3845 34.3679C68.0456 34.7309 67.6066 34.9996 67.0673 35.1739C66.528 35.3482 66.032 35.3818 65.5792 35.2747C65.1264 35.1677 64.732 34.9276 64.396 34.5547C64.06 34.1817 63.7918 33.6855 63.5916 33.0659C63.4065 32.4541 63.3447 31.9101 63.4062 31.434C63.4755 30.9427 63.6585 30.5287 63.9552 30.192C64.2597 29.8402 64.6758 29.5789 65.2036 29.4083C65.6511 29.2637 66.0727 29.2351 66.4685 29.3226C66.8643 29.4101 67.2096 29.5963 67.5045 29.8812C67.8108 30.1623 68.04 30.5381 68.192 31.0085C68.218 31.0889 68.2342 31.1977 68.2405 31.335C68.2432 31.4609 68.2366 31.5581 68.2207 31.6266L68.5156 30.6569L62.836 32.4927L63.2143 33.663L69.9781 31.4767C69.9707 31.4537 69.954 31.4021 69.9281 31.3218C69.9099 31.2263 69.8878 31.1384 69.8618 31.0581C69.5911 30.2205 69.2083 29.5459 68.7134 29.0342C68.2185 28.5225 67.638 28.1969 66.972 28.0574C66.3137 27.9026 65.5944 27.9514 64.8142 28.2036C64.034 28.4558 63.4012 28.8504 62.9158 29.3875C62.4419 29.9208 62.1387 30.5511 62.0063 31.2782C61.8853 32.0017 61.9583 32.7764 62.2253 33.6026C62.4886 34.4172 62.8753 35.0842 63.3854 35.6037C63.907 36.1194 64.5063 36.4643 65.1835 36.6382C65.8685 36.797 66.5781 36.7577 67.3124 36.5203Z"
        fill="url(#paint19_linear_651_1183)"
      />
      <path
        d="M76.318 21.4042L76.5484 34.0633L78.0856 34.0353L77.8552 21.3762L76.318 21.4042ZM80.3462 33.9942C81.5759 33.9718 82.6622 33.6867 83.605 33.1388C84.5478 32.591 85.2819 31.842 85.8073 30.8917C86.3325 29.9294 86.5836 28.8153 86.5605 27.5494C86.5375 26.2834 86.2461 25.1852 85.6865 24.2547C85.1266 23.3121 84.3656 22.5843 83.4035 22.0711C82.4414 21.558 81.3455 21.3126 80.1157 21.335L77.168 21.3887L77.1959 22.9259L80.1437 22.8722C80.855 22.8593 81.5082 22.9619 82.1031 23.1803C82.6978 23.3865 83.21 23.6968 83.6396 24.1111C84.0691 24.5133 84.4037 25.0077 84.6435 25.5943C84.8833 26.1809 85.01 26.8419 85.0233 27.5773C85.0367 28.3128 84.9343 28.978 84.716 29.5729C84.4976 30.1678 84.1813 30.6801 83.767 31.1098C83.3525 31.5274 82.852 31.8561 82.2654 32.0959C81.6786 32.3237 81.0295 32.444 80.3182 32.457L77.3704 32.5106L77.3984 34.0478L80.3462 33.9942Z"
        fill="url(#paint20_linear_651_1183)"
      />
      <path
        d="M90.5422 34.8863C91.42 35.0467 92.2106 35.0134 92.914 34.7865C93.6314 34.5498 94.2665 34.1265 94.8193 33.5166L93.796 32.5757C93.4084 33.0197 92.9784 33.3273 92.5058 33.4984C92.0355 33.6576 91.5215 33.6862 90.964 33.5844C90.4065 33.4825 89.9537 33.2772 89.6056 32.9684C89.2575 32.6597 89.0238 32.2615 88.9045 31.7739C88.7852 31.2863 88.7841 30.7222 88.9011 30.0817C89.0279 29.4552 89.2311 28.9468 89.5108 28.5566C89.8045 28.1567 90.1617 27.8788 90.5825 27.7228C91.0173 27.5571 91.5075 27.5241 92.0531 27.6238C92.5157 27.7083 92.9006 27.8828 93.2078 28.1473C93.5149 28.4118 93.7309 28.7393 93.8557 29.1299C93.9923 29.5226 94.0162 29.9621 93.9273 30.4484C93.9121 30.5315 93.8748 30.635 93.8154 30.759C93.7581 30.8711 93.7063 30.9535 93.6599 31.0063L94.3788 30.2919L88.5072 29.219L88.2861 30.429L95.2787 31.7066C95.283 31.6829 95.2928 31.6295 95.3079 31.5465C95.3371 31.4538 95.3593 31.3659 95.3745 31.2829C95.5327 30.4169 95.5151 29.6415 95.3215 28.9564C95.128 28.2714 94.7709 27.7097 94.2504 27.2714C93.7439 26.8233 93.0873 26.5256 92.2807 26.3783C91.4741 26.2309 90.7299 26.2788 90.048 26.5219C89.378 26.7672 88.8125 27.1787 88.3515 27.7564C87.9023 28.3363 87.5997 29.0532 87.4437 29.9073C87.2898 30.7495 87.3144 31.5201 87.5177 32.2192C87.7328 32.9204 88.0973 33.508 88.6114 33.9819C89.1395 34.4461 89.7831 34.7476 90.5422 34.8863Z"
        fill="url(#paint21_linear_651_1183)"
      />
      <path
        d="M102.539 32.9131L100.746 37.6494L102.099 38.1618L103.956 33.2563C104.311 32.3204 104.348 31.4899 104.068 30.765C103.803 30.0331 103.26 29.5113 102.436 29.1996C101.929 29.0074 101.435 28.9494 100.955 29.0255C100.486 29.1059 100.033 29.3338 99.5938 29.7092L100.055 28.4913L98.7017 27.9789L95.7555 35.76L97.1087 36.2724L98.9021 31.5361C99.0473 31.1526 99.2543 30.8442 99.5232 30.6108C99.8034 30.3816 100.12 30.2436 100.473 30.1968C100.841 30.1429 101.223 30.1907 101.618 30.3401C102.159 30.5451 102.509 30.8645 102.667 31.2983C102.825 31.732 102.782 32.2703 102.539 32.9131Z"
        fill="url(#paint22_linear_651_1183)"
      />
      <path
        d="M109.112 41.2662C108.961 41.4843 108.907 41.7326 108.949 42.0109C109.007 42.2863 109.146 42.4994 109.364 42.6504C109.592 42.8082 109.84 42.8628 110.109 42.8139C110.384 42.7552 110.597 42.6168 110.748 42.3986C110.906 42.1706 110.961 41.9223 110.912 41.6539C110.863 41.3855 110.724 41.1723 110.496 41.0145C110.278 40.8635 110.03 40.809 109.752 40.8509C109.483 40.8998 109.27 41.0382 109.112 41.2662Z"
        fill="url(#paint23_linear_651_1183)"
      />
      <path
        d="M125.236 38.8926L115.724 47.2488L116.738 48.4039L126.25 40.0477L125.236 38.8926ZM129.533 43.7846L121.456 43.6337L120.2 52.3446L121.454 53.7714L122.712 45.0095L130.787 45.2114L129.533 43.7846Z"
        fill="url(#paint24_linear_651_1183)"
      />
      <path
        d="M125.232 51.9312C124.514 52.3502 123.962 52.8882 123.577 53.5451C123.209 54.2064 123.027 54.9124 123.032 55.6632C123.037 56.414 123.243 57.1384 123.65 57.8364C124.063 58.5448 124.592 59.081 125.236 59.4449C125.887 59.8192 126.588 60.0039 127.339 59.9989C128.106 59.9983 128.849 59.7885 129.568 59.3695C130.297 58.9444 130.846 58.4012 131.214 57.74C131.589 57.0891 131.773 56.3883 131.768 55.6375C131.769 54.8971 131.563 54.1727 131.151 53.4643C130.744 52.7663 130.215 52.2302 129.564 51.8558C128.913 51.4815 128.209 51.2916 127.452 51.2862C126.702 51.2912 125.962 51.5062 125.232 51.9312ZM125.97 53.197C126.47 52.9055 126.974 52.7516 127.481 52.7353C128.004 52.7233 128.481 52.8359 128.913 53.073C129.35 53.3205 129.705 53.6786 129.979 54.1474C130.252 54.6162 130.385 55.0967 130.379 55.5888C130.379 56.0914 130.242 56.5622 129.967 57.0013C129.709 57.4448 129.33 57.8123 128.83 58.1037C128.33 58.3952 127.824 58.5439 127.311 58.5498C126.804 58.5661 126.326 58.4536 125.879 58.2121C125.447 57.975 125.095 57.6221 124.822 57.1533C124.549 56.6845 124.412 56.1988 124.412 55.6962C124.429 55.198 124.566 54.7272 124.824 54.2838C125.088 53.8507 125.47 53.4885 125.97 53.197Z"
        fill="url(#paint25_linear_651_1183)"
      />
      <path
        d="M141.855 63.8054L128.529 68.4389L129.005 69.8057L142.33 65.1721L141.855 63.8054ZM130.172 60.4952C129.329 60.7883 128.661 61.2183 128.169 61.7852C127.677 62.3521 127.369 62.9892 127.244 63.6963C127.12 64.4034 127.18 65.11 127.426 65.8161C127.656 66.4767 128.021 66.9945 128.521 67.3693C129.022 67.7442 129.627 67.9614 130.337 68.0209C131.051 68.0918 131.83 67.9807 132.673 67.6877C133.527 67.3906 134.206 66.9948 134.711 66.5002C135.231 66.013 135.571 65.4671 135.731 64.8625C135.891 64.2579 135.856 63.6253 135.626 62.9647C135.381 62.2586 134.99 61.6668 134.454 61.1894C133.917 60.712 133.28 60.4037 132.543 60.2645C131.816 60.1213 131.026 60.1982 130.172 60.4952ZM130.653 61.879C131.257 61.6692 131.811 61.6105 132.315 61.7032C132.824 61.8072 133.257 62.0266 133.616 62.3614C133.975 62.6962 134.238 63.1027 134.404 63.5811C134.543 63.9797 134.571 64.4103 134.49 64.8728C134.408 65.3352 134.2 65.765 133.866 66.1621C133.531 66.5592 133.062 66.8627 132.459 67.0726C131.855 67.2825 131.299 67.3355 130.791 67.2314C130.282 67.1274 129.852 66.9194 129.501 66.6074C129.15 66.2954 128.905 65.9401 128.767 65.5414C128.6 65.0631 128.554 64.5813 128.628 64.096C128.702 63.6107 128.906 63.1696 129.24 62.7725C129.578 62.3867 130.049 62.0889 130.653 61.879Z"
        fill="url(#paint26_linear_651_1183)"
      />
      <path
        d="M129.797 75.1415C129.927 76.0243 130.213 76.7624 130.653 77.3557C131.108 77.9591 131.713 78.4247 132.468 78.7523L133.03 77.4812C132.485 77.2567 132.056 76.9481 131.742 76.5555C131.44 76.1612 131.248 75.6836 131.166 75.1229C131.083 74.5622 131.132 74.0675 131.313 73.6387C131.494 73.2099 131.796 72.8607 132.219 72.5912C132.643 72.3217 133.176 72.1395 133.821 72.0447C134.455 71.9636 135.001 71.9928 135.461 72.1324C135.934 72.2822 136.312 72.5313 136.594 72.8797C136.891 73.2382 137.08 73.6919 137.16 74.2407C137.229 74.7059 137.187 75.1265 137.035 75.5023C136.883 75.8781 136.643 76.1878 136.313 76.4313C135.985 76.6868 135.576 76.8505 135.087 76.9225C135.004 76.9348 134.894 76.9327 134.757 76.9162C134.632 76.898 134.538 76.8754 134.473 76.8484L135.38 77.2999L134.511 71.3946L133.294 71.5737L134.329 78.6063C134.353 78.6028 134.407 78.5949 134.49 78.5826C134.588 78.5805 134.678 78.5733 134.761 78.561C135.632 78.4328 136.361 78.1671 136.948 77.7639C137.534 77.3607 137.952 76.8422 138.2 76.2085C138.461 75.5849 138.532 74.8675 138.413 74.0563C138.294 73.2451 138.009 72.5556 137.56 71.9879C137.113 71.4322 136.541 71.0288 135.846 70.7776C135.153 70.5384 134.377 70.482 133.518 70.6084C132.671 70.733 131.949 71.0038 131.352 71.4208C130.757 71.8496 130.318 72.3835 130.034 73.0225C129.764 73.6717 129.685 74.378 129.797 75.1415Z"
        fill="url(#paint27_linear_651_1183)"
      />
      <path
        d="M143.21 86.4005L130.683 84.5575L130.459 86.0786L142.986 87.9216L143.21 86.4005ZM130.13 88.3155C129.951 89.5323 130.055 90.6506 130.441 91.6703C130.827 92.69 131.446 93.5367 132.298 94.2104C133.161 94.8859 134.219 95.3158 135.472 95.5001C136.725 95.6844 137.856 95.5766 138.865 95.1767C139.887 94.7785 140.729 94.1468 141.393 93.2816C142.056 92.4163 142.478 91.3753 142.657 90.1584L143.086 87.2416L141.565 87.0178L141.136 89.9346C141.032 90.6385 140.824 91.266 140.511 91.8173C140.21 92.3702 139.821 92.8248 139.342 93.1809C138.875 93.5388 138.332 93.788 137.714 93.9287C137.096 94.0693 136.423 94.0861 135.696 93.9791C134.968 93.872 134.329 93.6621 133.777 93.3495C133.226 93.0368 132.772 92.641 132.416 92.162C132.072 91.6848 131.83 91.1372 131.689 90.5193C131.56 89.9031 131.548 89.2431 131.651 88.5393L132.08 85.6224L130.559 85.3986L130.13 88.3155Z"
        fill="url(#paint28_linear_651_1183)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_651_1183"
          x1="146.528"
          y1="100.081"
          x2="135.266"
          y2="108.693"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2801" />
          <stop offset="1" stopColor="#FF6B0A" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_651_1183"
          x1="141.7"
          y1="112.481"
          x2="130.097"
          y2="118.826"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2801" />
          <stop offset="1" stopColor="#FF6B0A" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_651_1183"
          x1="135.015"
          y1="123.102"
          x2="121.328"
          y2="126.796"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2801" />
          <stop offset="1" stopColor="#FF6B0A" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_651_1183"
          x1="125.912"
          y1="132.641"
          x2="112.781"
          y2="134.21"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2801" />
          <stop offset="1" stopColor="#FF6B0A" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_651_1183"
          x1="110.853"
          y1="142.613"
          x2="95.6585"
          y2="138.033"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2801" />
          <stop offset="1" stopColor="#FF6B0A" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_651_1183"
          x1="96.8693"
          y1="147.324"
          x2="84.5483"
          y2="142.522"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2801" />
          <stop offset="1" stopColor="#FF6B0A" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_651_1183"
          x1="85.3295"
          y1="148.91"
          x2="74.0742"
          y2="141.969"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2801" />
          <stop offset="1" stopColor="#FF6B0A" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_651_1183"
          x1="67.0747"
          y1="147.098"
          x2="59.946"
          y2="142.178"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2801" />
          <stop offset="1" stopColor="#FF6B0A" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_651_1183"
          x1="53.8674"
          y1="142.7"
          x2="47.263"
          y2="130.156"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2801" />
          <stop offset="1" stopColor="#FF6B0A" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_651_1183"
          x1="42.4928"
          y1="135.887"
          x2="38.1847"
          y2="123.384"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2801" />
          <stop offset="1" stopColor="#FF6B0A" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_651_1183"
          x1="33.2048"
          y1="127.572"
          x2="31.8114"
          y2="113.464"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2801" />
          <stop offset="1" stopColor="#FF6B0A" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_651_1183"
          x1="25.3383"
          y1="117.077"
          x2="25.9297"
          y2="103.867"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2801" />
          <stop offset="1" stopColor="#FF6B0A" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_651_1183"
          x1="17.9642"
          y1="100.594"
          x2="24.9891"
          y2="86.3642"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2801" />
          <stop offset="1" stopColor="#FF6B0A" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_651_1183"
          x1="15.6103"
          y1="85.9399"
          x2="22.4229"
          y2="74.606"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2801" />
          <stop offset="1" stopColor="#FF6B0A" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_651_1183"
          x1="15.9668"
          y1="74.317"
          x2="24.7002"
          y2="64.3874"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2801" />
          <stop offset="1" stopColor="#FF6B0A" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_651_1183"
          x1="20.7743"
          y1="56.5748"
          x2="26.8247"
          y2="50.3765"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2801" />
          <stop offset="1" stopColor="#FF6B0A" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_651_1183"
          x1="27.1474"
          y1="44.5115"
          x2="40.6077"
          y2="40.0618"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2801" />
          <stop offset="1" stopColor="#FF6B0A" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_651_1183"
          x1="35.8158"
          y1="34.3117"
          x2="48.8572"
          y2="32.1228"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2801" />
          <stop offset="1" stopColor="#FF6B0A" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_651_1183"
          x1="45.6138"
          y1="26.4528"
          x2="59.7569"
          y2="27.4283"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2801" />
          <stop offset="1" stopColor="#FF6B0A" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_651_1183"
          x1="57.2938"
          y1="20.4044"
          x2="70.2199"
          y2="23.1947"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2801" />
          <stop offset="1" stopColor="#FF6B0A" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_651_1183"
          x1="74.7698"
          y1="15.8574"
          x2="87.6163"
          y2="25.1744"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2801" />
          <stop offset="1" stopColor="#FF6B0A" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_651_1183"
          x1="89.3508"
          y1="15.9476"
          x2="99.4139"
          y2="24.5268"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2801" />
          <stop offset="1" stopColor="#FF6B0A" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_651_1183"
          x1="100.944"
          y1="18.2259"
          x2="109.296"
          y2="28.4784"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2801" />
          <stop offset="1" stopColor="#FF6B0A" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_651_1183"
          x1="117.666"
          y1="25.8895"
          x2="122.774"
          y2="32.885"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2801" />
          <stop offset="1" stopColor="#FF6B0A" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_651_1183"
          x1="128.468"
          y1="34.1268"
          x2="130.633"
          y2="48.1374"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2801" />
          <stop offset="1" stopColor="#FF6B0A" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_651_1183"
          x1="137.074"
          y1="44.2962"
          x2="137.107"
          y2="57.52"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2801" />
          <stop offset="1" stopColor="#FF6B0A" />
        </linearGradient>
        <linearGradient
          id="paint26_linear_651_1183"
          x1="143.258"
          y1="55.2746"
          x2="139.986"
          y2="69.0686"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2801" />
          <stop offset="1" stopColor="#FF6B0A" />
        </linearGradient>
        <linearGradient
          id="paint27_linear_651_1183"
          x1="147.351"
          y1="67.9326"
          x2="142.457"
          y2="80.2175"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2801" />
          <stop offset="1" stopColor="#FF6B0A" />
        </linearGradient>
        <linearGradient
          id="paint28_linear_651_1183"
          x1="148.935"
          y1="85.7803"
          x2="137.642"
          y2="96.9301"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2801" />
          <stop offset="1" stopColor="#FF6B0A" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export const LinkedIn = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4006 9.7566H15.6002V12.3466C16.3492 10.857 18.27 9.5186 21.1554 9.5186C26.6868 9.5186 28 12.4838 28 17.9242V28H22.4V19.1632C22.4 16.065 21.651 14.3178 19.7442 14.3178C17.0996 14.3178 16.0006 16.2008 16.0006 19.1618V28H10.4006V9.7566V9.7566ZM0.798 27.762H6.398V9.5186H0.798V27.762V27.762ZM7.2002 3.57C7.20041 4.03939 7.10732 4.50413 6.92634 4.93722C6.74537 5.37031 6.48011 5.76312 6.146 6.0928C5.46896 6.76567 4.55253 7.14231 3.598 7.14C2.64515 7.13936 1.73084 6.76368 1.0528 6.0942C0.719896 5.7634 0.45554 5.37015 0.274881 4.937C0.0942221 4.50386 0.000810785 4.03931 0 3.57C0 2.6222 0.378 1.715 1.0542 1.0458C1.73164 0.375422 2.64634 -0.000418895 3.5994 3.5037e-07C4.5542 3.5037e-07 5.4698 0.3766 6.146 1.0458C6.8208 1.715 7.2002 2.6222 7.2002 3.57Z"
        fill="url(#paint0_linear_704_403)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_704_403"
          x1="4.35849"
          y1="4.81121e-07"
          x2="29.9811"
          y2="29.717"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00619038" stopColor="#FF3303" />
          <stop offset="1" stopColor="#FF6C0B" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export const CallIcon = () => {
  return (
    <svg
      className="me-2"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.0002 10.999H22.0002C22.0002 5.869 18.1272 2 12.9902 2V4C17.0522 4 20.0002 6.943 20.0002 10.999Z"
        fill="#F4F4F4"
      />
      <path
        d="M13.0003 7.99999C15.1033 7.99999 16.0003 8.89699 16.0003 11H18.0003C18.0003 7.77499 16.2253 5.99999 13.0003 5.99999V7.99999ZM16.4223 13.443C16.2301 13.2683 15.9776 13.1752 15.7181 13.1832C15.4585 13.1912 15.2123 13.2998 15.0313 13.486L12.6383 15.947C12.0623 15.837 10.9043 15.476 9.71228 14.287C8.52028 13.094 8.15928 11.933 8.05228 11.361L10.5113 8.96699C10.6977 8.78612 10.8064 8.53982 10.8144 8.2802C10.8225 8.02059 10.7292 7.76804 10.5543 7.57599L6.85928 3.51299C6.68432 3.32035 6.44116 3.2035 6.18143 3.18725C5.92171 3.17101 5.66588 3.25665 5.46828 3.42599L3.29828 5.28699C3.12539 5.46051 3.0222 5.69145 3.00828 5.93599C2.99328 6.18599 2.70728 12.108 7.29928 16.702C11.3053 20.707 16.3233 21 17.7053 21C17.9073 21 18.0313 20.994 18.0643 20.992C18.3088 20.9783 18.5396 20.8747 18.7123 20.701L20.5723 18.53C20.7417 18.3325 20.8276 18.0768 20.8115 17.817C20.7954 17.5573 20.6788 17.3141 20.4863 17.139L16.4223 13.443Z"
        fill="#F4F4F4"
      />
    </svg>
  );
};
export const ListCheckIcon = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.06634 9.81344L5.63301 8.38011C5.51079 8.25789 5.35523 8.19678 5.16634 8.19678C4.97745 8.19678 4.8219 8.25789 4.69967 8.38011C4.57745 8.50233 4.51634 8.65789 4.51634 8.84678C4.51634 9.03567 4.57745 9.19122 4.69967 9.31344L6.59967 11.2134C6.73301 11.3468 6.88856 11.4134 7.06634 11.4134C7.24412 11.4134 7.39967 11.3468 7.53301 11.2134L11.2997 7.44678C11.4219 7.32456 11.483 7.169 11.483 6.98011C11.483 6.79122 11.4219 6.63567 11.2997 6.51344C11.1775 6.39122 11.0219 6.33011 10.833 6.33011C10.6441 6.33011 10.4886 6.39122 10.3663 6.51344L7.06634 9.81344ZM7.99967 15.2801C7.07745 15.2801 6.21079 15.105 5.39967 14.7548C4.58856 14.4046 3.88301 13.9297 3.28301 13.3301C2.68301 12.7301 2.20812 12.0246 1.85834 11.2134C1.50856 10.4023 1.33345 9.53567 1.33301 8.61344C1.33301 7.69122 1.50812 6.82456 1.85834 6.01344C2.20856 5.20233 2.68345 4.49678 3.28301 3.89678C3.88301 3.29678 4.58856 2.82189 5.39967 2.47211C6.21079 2.12233 7.07745 1.94722 7.99967 1.94678C8.9219 1.94678 9.78856 2.12189 10.5997 2.47211C11.4108 2.82233 12.1163 3.29722 12.7163 3.89678C13.3163 4.49678 13.7915 5.20233 14.1417 6.01344C14.4919 6.82456 14.6668 7.69122 14.6663 8.61344C14.6663 9.53567 14.4912 10.4023 14.141 11.2134C13.7908 12.0246 13.3159 12.7301 12.7163 13.3301C12.1163 13.9301 11.4108 14.4052 10.5997 14.7554C9.78856 15.1057 8.9219 15.2806 7.99967 15.2801ZM7.99967 13.9468C9.48856 13.9468 10.7497 13.4301 11.783 12.3968C12.8163 11.3634 13.333 10.1023 13.333 8.61344C13.333 7.12456 12.8163 5.86344 11.783 4.83011C10.7497 3.79678 9.48856 3.28011 7.99967 3.28011C6.51079 3.28011 5.24967 3.79678 4.21634 4.83011C3.18301 5.86344 2.66634 7.12456 2.66634 8.61344C2.66634 10.1023 3.18301 11.3634 4.21634 12.3968C5.24967 13.4301 6.51079 13.9468 7.99967 13.9468Z"
        fill="#A2A2A2"
      />
    </svg>
  );
};
