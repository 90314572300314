import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/img/png/logo.png";

const ThankUPage = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="conatainer vh-100 d-flex flex-column justify-content-center align-items-center">
        <div className="thank_u_card mx-3">
          <div className="text-center">
            <img
              onClick={() => navigate("/")}
              className="cursor-pointer thank_u_logo"
              src={logo}
              alt="logo"
            />
          </div>
          <h2 className="mt-3">Thank you for booking a call with our team</h2>
          <p className="mb-0 pt-2">
            We appreciate you taking the time to book a call with our team at
            Kodeden.com! We're thrilled to have the opportunity to guide you on
            your path to becoming a successful Software Engineer. Our passion
            lies in empowering aspiring developers like yourself to unlock their
            full potential.
          </p>
          <p className="pt-3">
            Your scheduled call with us is a chance for us to understand your
            goals, strengths, and areas that need improvement. Together, we'll
            develop a personalized learning plan that best suits your needs and
            ambitions.
          </p>
          <p className="">
            Please ensure that you have your preferred contact method ready, as
            we will be getting in touch with you at the scheduled time. If you
            need to reschedule or have any questions, don't hesitate to reach
            out to us at <a href="mailto:kody@kodeden.com">kody@kodeden.com</a>{" "}
            .
          </p>
          <p>
            Once again, thank you for choosing Kodeden as your partner in this
            exciting journey. We look forward to helping you transform your
            passion for coding into a rewarding career.
          </p>
          <p className="mb-1 fw-bold ">Warm regards,</p>
          <p className="mb-1 fw-bold">Kody Doherty</p>
          <p className="mb-1 fw-bold">CEO & Lead Instructor</p>
          <a href="Kodeden.com">Kodeden.com</a>
          <div className="text-center">
            <button
              onClick={() => navigate("/")}
              className=" d-block text-center mx-auto text-uppercase common-btn hero-btn position-relative mb-sm-3 mb-2 mt-sm-4 mt-3"
            >
              <span className="d-flex align-items-center">Back to home</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankUPage;
