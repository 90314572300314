import React from "react";
import Slider from "react-slick";
import { sliderData } from "./common/Helper";
import { SliderLeftArrow, SliderRightArrow } from "./common/Icons";
import TestimonialCard from "./TestimonialCard";

const Testiminals = () => {
  const settings = {
    infinite: false,
    speed: 800,
    slidesToShow: 3.7,
    slidesToScroll: 1,
    autoplay: false,
    dots: false,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Slider className="mb-5 pb-4 pb-lg-5" {...settings}>
        {sliderData.map((obj, index) => (
          <TestimonialCard obj={obj} key={index} />
        ))}
      </Slider>
    </>
  );
};

export default Testiminals;
function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="slider-right-arrow d-flex justify-content-center align-items-center position-absolute ms-3 ms-sm-0"
      onClick={onClick}
    >
      <SliderRightArrow />
    </div>
  );
}
function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="slider-left-arrow d-flex justify-content-center align-items-center position-absolute ms-3 ms-sm-0"
      onClick={onClick}
    >
      <SliderLeftArrow />
    </div>
  );
}
